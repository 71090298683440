import React, { useState } from 'react';
import PageTitle from '../element/page-title';
import Footer2 from '../layout/footer2';
// import { Link } from 'react-router-dom';
// import { Row, Col, Card } from 'react-bootstrap';
import Header2 from '../layout/header2';
import SettingsSubmenu from '../layout/settings-submenu';
import Sidebar from '../layout/sidebar';
import { useMyInfo } from '../../state/hooks';
import { useContext } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import { useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup'
import Axios from "axios"
import { BACKEND_API_URL, HTTP_SUCCESS_STATUS, MAX_IMAGE_SIZE, USER_STATUS } from '../../shared/constants';
import { escapeHtml, escapeHtmlObject } from '../../shared/helpers';
import { isEmpty, isUndefined } from 'lodash';
import { useRef } from 'react';
import { toast } from 'react-hot-toast';
import { ImageContainer } from './exchange';
import UserAvatar from '../../images/profile/unknown.png'
import { LinearProgress } from '@mui/material';
import { Button } from 'react-bootstrap';
import { COUNTRY_NAMES } from '../../shared/country';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

function Settings() {

    const history = useHistory()

    const { currentUser, authToken, isAuthenticated } = useContext(AuthContext)

    const { data, refetch: userInfoRefetch, isRefetching: userInfoRefetching } = useMyInfo(authToken, currentUser?.id)

    const [isgeneralInfoLoading, setIsGeneralInfoLoading] = useState(false)

    const [isPasswordChanging, setIsPasswordChanging] = useState(false)

    const [isPersonalInfoChanging, setIsPersonalInfoChanging] = useState(false)

    const avatarRef = useRef()

    const passwordRef = useRef()

    const confirmPasswordRef = useRef()

    const generalInfoFormik = useFormik({
        initialValues: {
            name: "",
            avatar: "",
        },
        initialErrors: {
            email: "",
            avatar: ""
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().min(3, "User  name must be longer than 3 characters.").max(255, "User  name must be shorter than 255 characters.").required("Please enter user name."),
        }),
        handleSubmit: async () => {

        },
        onSubmit: async () => {

            // check if logged in

            if (!isAuthenticated || isEmpty(currentUser) || !authToken || currentUser.status == USER_STATUS.VERIFYING || currentUser.status == USER_STATUS.VERIFIED) {
                toast.error("Please login to your account or already verified.")
                return false
            }

            if (!isUndefined(avatarRef.current.files[0]) && avatarRef.current.files[0].size > MAX_IMAGE_SIZE) {
                toast.error("File size is limited to be less than 1MB.")
                return false
            }

            // redirect to confirm password if last verified earlier than yesterday

            if (!data?.user?.password_last_verified_at || moment(data?.user?.password_last_verified_at).valueOf() < moment().subtract(1, 'days').valueOf()) {

                history.push('/confirm-password?from=settings')
                return false
            }

            const submittedData = new FormData()

            submittedData.append('name', escapeHtml(generalInfoFormik.values.name))
            submittedData.append('file', avatarRef.current.files[0])

            const _toast = toast.loading("Submitting Data...")
            setIsGeneralInfoLoading(true)

            try {

                // send listing request
                const generalInfoResult = await Axios.post(`${BACKEND_API_URL}/user/general-profile`, submittedData, { headers: { Authorization: `Bearer ${authToken}` } })

                if (generalInfoResult.status != HTTP_SUCCESS_STATUS) {
                    toast.dismiss(_toast)
                    toast.error(generalInfoResult?.data?.message)
                    setIsGeneralInfoLoading(false)
                    return false
                }

                toast.dismiss(_toast)
                toast.success("Successfully Changed!")

                await userInfoRefetch()
            }
            catch (e) {
                console.log(e)
                if (e?.response?.data?.message) {
                    console.log("Profile-Page@general-profile-error:", e?.response?.data?.message)
                    toast.error(e?.response?.data?.message)
                } else {
                    console.error('Profile-Page@general-profile-error:', e.message)
                    toast.error(e.message)
                }
            }

            setIsGeneralInfoLoading(false)
            return false
        },
    })

    const personalInfoFormik = useFormik({
        initialValues: {
            birth_date: "",
            address: "",
            city: "",
            zip_code: "",
            country: "",
        },
        initialErrors: {
            birth_date: "",
            address: "",
            city: "",
            zip_code: "",
            country: ""
        },
        validationSchema: Yup.object().shape({
            birth_date: Yup.string().min(5, "Please enter valid date of birth.").required("Please enter date of birth"),
            address: Yup.string().min(3, "Address must be longer than 3 characters.").required("Please enter Address."),
            permanent_address: Yup.string().min(3, "Address must be longer than 3 characters.").required("Please enter Address."),
            city: Yup.string().min(3, "City must be longer than 3 characters.").required("Please enter city name."),
            zip_code: Yup.string().min(3, "Zip code must be longer than 3 characters.").required("Please enter zip code."),
            country: Yup.string().min(3, "Country must be longer than 3 characters.").required("Please enter country name."),
        }),
        handleSubmit: async () => {

        },
        onSubmit: async () => {

            // check if logged in

            if (!isAuthenticated || isEmpty(currentUser) || !authToken || currentUser.status == USER_STATUS.VERIFYING || currentUser.status == USER_STATUS.VERIFIED) {
                toast.error("Please login to list your business token.")
                return false
            }

            if (!data?.user?.password_last_verified_at || moment(data?.user?.password_last_verified_at).valueOf() < moment().subtract(1, 'days').valueOf()) {

                history.push('/confirm-password?from=settings')
                return false
            }

            const _toast = toast.loading("Submitting data...")
            setIsPersonalInfoChanging(true)

            try {

                const submittedData = escapeHtmlObject({ ...personalInfoFormik.values })

                // send listing request
                const generalInfoResult = await Axios.post(`${BACKEND_API_URL}/user/personal-info`, submittedData, { headers: { Authorization: `Bearer ${authToken}` } })

                if (generalInfoResult.status !== HTTP_SUCCESS_STATUS) {
                    toast.dismiss(_toast)
                    toast.error(generalInfoResult?.data?.message)
                    setIsPersonalInfoChanging(false)
                    return false
                }

                toast.dismiss(_toast)
                toast.success("Personal information successfully updated!")
            }
            catch (e) {
                console.log(e)
                toast.dismiss(_toast)
                if (e?.response?.data?.message) {
                    console.log("Profile-Page@personal-info-error:", e?.response?.data?.message)
                    toast.error(e?.response?.data?.message)
                } else {
                    console.error('Profile-Page@personal-info-error:', e.message)
                    toast.error(e.message)
                }
            }
            setIsPersonalInfoChanging(false)
            userInfoRefetch()
            return false
        },
    })

    useEffect(() => {
        userInfoRefetch()
    }, [])

    useEffect(() => {
        generalInfoFormik.setValues(prev => ({ ...prev, name: data?.user?.name }))
        const prefilledValues = { birth_date: moment(data?.user?.birth_date).format('MM/DD/yyyy'), address: data?.user?.address, permanent_address: data?.user?.address, zip_code: data?.user?.zip_code, city: data?.user?.city, country: data?.user?.country }
        personalInfoFormik.setValues(prev => ({ ...prev, ...prefilledValues }))
    }, [data])

    const handlePasswordChange = async () => {

        // check if logged in

        if (!isAuthenticated || isEmpty(currentUser) || !authToken || currentUser.status == USER_STATUS.VERIFYING || currentUser.status == USER_STATUS.VERIFIED) {
            toast.error("Please login or your account is verified already.")
            return false
        }

        // check if password is valid or not

        const password = passwordRef.current.value
        const confirmPassword = confirmPasswordRef.current.value

        if (!password || password.length < 8) {
            toast.error("Password should be longer than 8 characters.")
            return false
        }

        if (password != confirmPassword) {
            toast.error("Password doesn't match.")
            return false
        }

        if (!data?.user?.password_last_verified_at || moment(data?.user?.password_last_verified_at).valueOf() < moment().subtract(1, 'days').valueOf()) {

            history.push('/confirm-password?from=settings')
            return false
        }

        const _toast = toast.loading("Changing password...")
        setIsPasswordChanging(true)

        try {

            // send listing request
            const changeResult = await Axios.post(`${BACKEND_API_URL}/user/change-password`, { password }, { headers: { Authorization: `Bearer ${authToken}` } })

            if (changeResult.status !== HTTP_SUCCESS_STATUS) {
                toast.dismiss(_toast)
                toast.error(changeResult?.data?.message)
                setIsPasswordChanging(false)
                return false
            }

            toast.dismiss(_toast)
            toast.success("Password Successfully Changed!")
        }

        catch (e) {
            console.log("change-password", e)
            toast.dismiss(_toast)
            if (e?.response?.data?.message) {
                console.log("Profile-Page@change-password-error:", e?.response?.data?.message)
                toast.error(e?.response?.data?.message)
            } else {
                console.error('Profile-Page@change-password-error:', e.message)
                toast.error(e.message)
            }
        }
        setIsPasswordChanging(false)
        return false
    }

    return (
        <>
            <Header2 activePage={`settings`} />
            <PageTitle />

            <div className="content-body">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card sub-menu">
                                <div className="card-body">
                                    <SettingsSubmenu activePage={`settings`} data={data} />
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12">
                            <div className="row">
                                <div className="col-xl-6 col-md-6">
                                    <div className="card">
                                        <div className="card-header">
                                            <h4 className="card-title">User Profile</h4>
                                        </div>
                                        {generalInfoFormik.isSubmitting && <LinearProgress color='info' />}
                                        <div className="card-body">
                                            <div className="d-flex align-items-center mb-3">
                                                {!isEmpty(data) && data?.user?.avatar ? <ImageContainer className="me-3 rounded-circle me-0 me-sm-3"
                                                    src={`${BACKEND_API_URL}/files/${data?.user?.avatar}`} width="50" height="50" alt="" /> : <img src={UserAvatar} width="50" height="50" />}
                                                <div className="flex-grow-1">
                                                    <h5 className="mb-0">{!isEmpty(data) ? data?.user?.name : ` - `}</h5>
                                                    <p className="mb-0">Max file size is 1MB
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="mb-3 col-xl-12">
                                                    <label className="form-label">User Name</label>
                                                    <input type="text" className="form-control" placeholder="Name"
                                                        id="name"
                                                        name="name"
                                                        value={generalInfoFormik.values.name}
                                                        onChange={generalInfoFormik.handleChange}
                                                        onBlur={generalInfoFormik.handleBlur}
                                                        disabled={isUndefined(data) || data?.user?.status === USER_STATUS.VERIFYING || data?.user?.status === USER_STATUS.VERIFIED}
                                                    />
                                                    {generalInfoFormik.touched.name && generalInfoFormik.errors.name ? (
                                                        <div className="text-danger">
                                                            {generalInfoFormik.errors.name}
                                                        </div>
                                                    ) : ``}
                                                </div>
                                                <div className="mb-3 col-xl-12">
                                                    <div className="file-upload-wrapper" data-text="Change Photo">
                                                        <input
                                                            type="file"
                                                            className="file-upload-field"
                                                            id="avatar"
                                                            name="avatar"
                                                            ref={avatarRef}
                                                            value={generalInfoFormik.values.avatar}
                                                            onChange={generalInfoFormik.handleChange}
                                                            onBlur={generalInfoFormik.handleBlur}
                                                            disabled={isUndefined(data) || data?.user?.status === USER_STATUS.VERIFYING || data?.user?.status === USER_STATUS.VERIFIED}
                                                        />
                                                        {generalInfoFormik.touched.avatar && generalInfoFormik.errors.avatar ? (
                                                            <div className="text-danger">
                                                                {generalInfoFormik.errors.avatar}
                                                            </div>
                                                        ) : ``}
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    {!generalInfoFormik.isSubmitting && !isgeneralInfoLoading ? <Button onClick={generalInfoFormik.handleSubmit} className="btn btn-success waves-effect px-4">Save</Button> : <i className="fa fa-spinner text-primary fa-spin fa-3x fa-fw"></i>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-md-6">
                                    <div className="card">
                                        <div className="card-header">
                                            <h4 className="card-title">User Credentials</h4>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="mb-3 col-xl-12">
                                                    <label className="form-label">Email</label>
                                                    <input type="email" className="form-control" placeholder="Email" disabled value={!isEmpty(data) ? data?.user.email : ``} />
                                                </div>
                                                <div className="mb-3 col-xl-12">
                                                    <label className="form-label">New Password</label>
                                                    <input type="password" className="form-control"
                                                        placeholder="" ref={passwordRef} />
                                                </div>
                                                <div className="mb-3 col-xl-12">
                                                    <label className="form-label">Confirm Password</label>
                                                    <input type="password" className="form-control"
                                                        placeholder="" ref={confirmPasswordRef} />
                                                </div>
                                                <div className="col-12">
                                                    <button className="btn btn-success waves-effect px-4" onClick={() => { handlePasswordChange() }}>Save</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <h4 className="card-title">Personal Information</h4>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="mb-3 col-xl-6 col-md-6">
                                                    <label className="form-label">Date of birth</label>
                                                    <div>
                                                        <input className="form-control"
                                                            type='text'
                                                            id="birth_date"
                                                            name="birth_date"
                                                            value={personalInfoFormik.values.birth_date}
                                                            onChange={personalInfoFormik.handleChange}
                                                            onBlur={personalInfoFormik.handleBlur}
                                                            disabled={isUndefined(data) || data?.user?.status === USER_STATUS.VERIFYING || data?.user?.status === USER_STATUS.VERIFIED}
                                                        />
                                                    </div>
                                                    {personalInfoFormik.touched.birth_date && personalInfoFormik.errors.birth_date ? (
                                                        <div className="text-danger">
                                                            {personalInfoFormik.errors.birth_date}
                                                        </div>
                                                    ) : ``}
                                                </div>
                                                <div className="mb-3 col-xl-6 col-md-6">
                                                    <label className="form-label">Present Address</label>
                                                    <input type="text" className="form-control"
                                                        placeholder="56, Old Street, Brooklyn"
                                                        id="address"
                                                        name="address"
                                                        value={personalInfoFormik.values.address}
                                                        onChange={personalInfoFormik.handleChange}
                                                        onBlur={personalInfoFormik.handleBlur}
                                                        disabled={isUndefined(data) || data?.user?.status === USER_STATUS.VERIFYING || data?.user?.status === USER_STATUS.VERIFIED}
                                                    />
                                                    {personalInfoFormik.touched.address && personalInfoFormik.errors.address ? (
                                                        <div className="text-danger">
                                                            {personalInfoFormik.errors.address}
                                                        </div>
                                                    ) : ``}
                                                </div>
                                                <div className="mb-3 col-xl-6 col-md-6">
                                                    <label className="form-label">Permanent Address</label>
                                                    <input type="text" className="form-control"
                                                        placeholder="123, Central Square, Brooklyn"
                                                        id="permanent_address"
                                                        name="permanent_address"
                                                        value={personalInfoFormik.values.permanent_address}
                                                        onChange={personalInfoFormik.handleChange}
                                                        onBlur={personalInfoFormik.handleBlur}
                                                        disabled={isUndefined(data) || data?.user?.status === USER_STATUS.VERIFYING || data?.user?.status === USER_STATUS.VERIFIED}
                                                    />
                                                    {personalInfoFormik.touched.permanent_address && personalInfoFormik.errors.permanent_address ? (
                                                        <div className="text-danger">
                                                            {personalInfoFormik.errors.permanent_address}
                                                        </div>
                                                    ) : ``}
                                                </div>
                                                <div className="mb-3 col-xl-6 col-md-6">
                                                    <label className="form-label">City</label>
                                                    <input type="text" className="form-control" placeholder="New York"
                                                        id="city"
                                                        name="city"
                                                        value={personalInfoFormik.values.city}
                                                        onChange={personalInfoFormik.handleChange}
                                                        onBlur={personalInfoFormik.handleBlur}
                                                        disabled={isUndefined(data) || data?.user?.status === USER_STATUS.VERIFYING || data?.user?.status === USER_STATUS.VERIFIED}
                                                    />
                                                    {personalInfoFormik.touched.city && personalInfoFormik.errors.city ? (
                                                        <div className="text-danger">
                                                            {personalInfoFormik.errors.city}
                                                        </div>
                                                    ) : ``}
                                                </div>
                                                <div className="mb-3 col-xl-6 col-md-6">
                                                    <label className="form-label">Postal Code</label>
                                                    <input type="text" className="form-control" placeholder="25481"
                                                        id="zip_code"
                                                        name="zip_code"
                                                        value={personalInfoFormik.values.zip_code}
                                                        onChange={personalInfoFormik.handleChange}
                                                        onBlur={personalInfoFormik.handleBlur}
                                                        disabled={isUndefined(data) || data?.user?.status === USER_STATUS.VERIFYING || data?.user?.status === USER_STATUS.VERIFIED}
                                                    />
                                                    {personalInfoFormik.touched.zip_code && personalInfoFormik.errors.zip_code ? (
                                                        <div className="text-danger">
                                                            {personalInfoFormik.errors.zip_code}
                                                        </div>
                                                    ) : ``}
                                                </div>
                                                <div className="mb-3 col-xl-6 col-md-6">
                                                    <label className="form-label">Country</label>
                                                    <select className="form-control" name="country" id="country"
                                                        value={personalInfoFormik.values.country}
                                                        onChange={personalInfoFormik.handleChange}
                                                        onBlur={personalInfoFormik.handleBlur}
                                                        disabled={isUndefined(data) || data?.user?.status === USER_STATUS.VERIFYING || data?.user?.status === USER_STATUS.VERIFIED}
                                                    >
                                                        <option value="">Select</option>
                                                        {COUNTRY_NAMES.map((value, index) => (
                                                            <option key={`settings-country-select-${index}`} value={value}>{value}</option>
                                                        ))}
                                                    </select>
                                                    {personalInfoFormik.touched.country && personalInfoFormik.errors.country ? (
                                                        <div className="text-danger">
                                                            {personalInfoFormik.errors.country}
                                                        </div>
                                                    ) : ``}
                                                </div>

                                                <div className="mb-3 col-12">
                                                    {!personalInfoFormik.isSubmitting && !isPersonalInfoChanging ? <Button onClick={personalInfoFormik.handleSubmit}>Save</Button> : <i className="fa fa-spinner text-primary fa-spin fa-3x fa-fw"></i>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer2 />
        </>
    )
}

export default Settings;