import React from 'react'
import { useState } from "react"
import { useEffect } from "react"
import Web3 from "web3"
import { CHAIN_LIST, FARMING_ADDRESSES } from "../../shared/constants"
import { getPoolUserInfoList, getUserReward } from "../../shared/web3"
import { CircularProgress } from "@mui/material"
import BigNumber from 'bignumber.js'
import CountUp from 'react-countup';
import { useRef } from 'react'
import PerfectScrollbar from "react-perfect-scrollbar";
import { max, min } from 'lodash'
import { toFixed } from '../../shared/helpers'

export const PendingAmount = ({ account, poolIndex, chainId, decimal }) => {

    const [userInfo, setUserInfo] = useState({})
    const [isLoading, setLoading] = useState(true)

    const prevRef = useRef("")

    useEffect(() => {

        const fetchUserData = async () => {

            if (!account) {
                return false
            }

            const chain = CHAIN_LIST.find(_chain => _chain.chainId === chainId)
            const web3 = new Web3(chain?.rpcUrls[0])
            const farmingAddress = FARMING_ADDRESSES[chain.chainId] === undefined ? FARMING_ADDRESSES['0x1'] : FARMING_ADDRESSES[chain.chainId]

            try {
                const _userInfo = await getUserReward(account, poolIndex, farmingAddress, web3)
                setUserInfo(_userInfo)
            }
            catch (e) {
                console.log("Error-fetchUserData", e)
            }
            finally {
                setLoading(false)
            }

            return false
        }

        const userDataInterval = setInterval(fetchUserData, 4000)

        return () => {
            clearInterval(userDataInterval)
        }

    }, [account])

    useEffect(() => {
        prevRef.current = +convertUnits(userInfo?.pendingAmomunt)

    }, [userInfo])

    const convertUnits = (_amount) => {
        //const expo = max([decimal - 12, 0])
        return new BigNumber(_amount).shiftedBy(-decimal).toString()
    }

    return (
        <h4 className='text-primary' style={{ width: "200px" }}><PerfectScrollbar>{!isLoading ? +convertUnits(userInfo?.pendingAmomunt ?? 0) ?? " - " : <CircularProgress style={{ width: "20px", height: "20px" }} />}</PerfectScrollbar></h4>
    )
}