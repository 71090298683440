import React from 'react';
import { Modal, Button, Card, OverlayTrigger, Tooltip, Accordion } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { CHAIN_LIST, FARMING_ADDRESSES, UNSTAKE_FEES } from '../../shared/constants';
import { useEffect } from 'react';
import { FARMINGABI, getPoolUserInfoList, getTokenBalance } from '../../shared/web3';
import Web3 from 'web3';
import { useState } from 'react';
import BigNumber from 'bignumber.js';
import moment from 'moment';
import { getAPY } from '../../shared/helpers';
import { isEmpty } from 'lodash';
import { toast } from 'react-hot-toast';
import { useRef } from 'react';
import { erc20ABI, useAccount, useContractWrite, useNetwork } from 'wagmi';
import { getContractArgFigure } from '../../shared/web3';
import { waitForTransaction } from '@wagmi/core';

export function StakeDialog({ onHide, poolData, show, setReloading }) {
  const { chainId, symbol, name, index, poolInfo, decimal } = poolData;

  const { isConnected, address } = useAccount();

  const { chain } = useNetwork();

  const [stakeAmount, setStakeAmount] = useState(0);
  const [stakedAmount, setStakedAmount] = useState(false);
  const [tokenBalance, setTokenBalance] = useState(0);
  const [isStaking, setIsStaking] = useState(false);
  const [isUnstaking, setIsUnstaking] = useState(false);

  const inputRef = useRef('0');

  const checkedRef = useRef();

  useEffect(() => {
    const fetchWalletBalance = async () => {
      const tokenChain = CHAIN_LIST.find((_chain) => _chain.chainId === chainId);
      const web3 = new Web3(tokenChain?.rpcUrls[0]);
      try {
        const farmingAddress =
          FARMING_ADDRESSES[tokenChain.chainId] === undefined
            ? FARMING_ADDRESSES['0x1']
            : FARMING_ADDRESSES[tokenChain.chainId];
        const _userInfo = await getPoolUserInfoList(address, index, farmingAddress, web3);
        const _stakedAmount = new BigNumber(_userInfo?.userInfo.amount).shiftedBy(-decimal).toFixed(3);
        setStakedAmount(+_stakedAmount);

        const walletBalance = await getTokenBalance(address, poolInfo?.lpToken, web3, decimal);
        if (isNaN(+walletBalance) || +walletBalance <= 0) {
          toast.error(`Please deposit ${name} into your wallet!`);
        }
        setTokenBalance(walletBalance);
      } catch (e) {
        console.log('Error-fetchWalletBalance', e);
        setTokenBalance(0);
      }
      return false;
    };

    fetchWalletBalance();
  }, [symbol]);

  const { writeAsync: approveWrite } = useContractWrite({
    chainId: chain?.id,
    address: poolInfo?.lpToken,
    abi: erc20ABI,
    functionName: 'approve',
    enabled: false,
  });

  const { writeAsync: stakeWrite } = useContractWrite({
    chainId: chain?.id,
    address: FARMING_ADDRESSES[chainId] ?? FARMING_ADDRESSES['0x1'],
    abi: FARMINGABI,
    functionName: 'stake',
    enabled: false,
  });

  const { writeAsync: unstakeWrite } = useContractWrite({
    chainId: chain?.id,
    address: FARMING_ADDRESSES[chainId] ?? FARMING_ADDRESSES['0x1'],
    abi: FARMINGABI,
    functionName: 'unStake',
    enabled: false,
  });

  const handleStake = async () => {
    const agreementChecked = checkedRef.current.checked;

    if (!agreementChecked) {
      toast.error('Please agree to the Caladex Staking Service Agreement.');
      return false;
    }
    const minStakableAmount = new BigNumber(poolInfo?.minAmount).shiftedBy(-decimal).toFixed(3);

    if (!stakeAmount || stakeAmount <= 0) {
      toast.error('Please input stake amount.');
      inputRef.current.classList.add('is-invalid');
      inputRef.current.focus();
      return false;
    }

    if (stakeAmount > +tokenBalance) {
      toast.error('Stake amount should be less than balance in wallet.');
      inputRef.current.focus();
      return false;
    }

    if (!stakedAmount && stakeAmount < +minStakableAmount) {
      toast.error('Stake amount should be greater than minimum stake amount!');
      inputRef.current.focus();
      return false;
    }

    const _toast = toast.loading('Staking token...');
    setIsStaking(true);
    try {
      const farmingAddress =
        FARMING_ADDRESSES[chainId] === undefined ? FARMING_ADDRESSES['0x1'] : FARMING_ADDRESSES[chainId];
      // await approveToken(address, poolInfo?.lpToken, farmingAddress, +stakeAmount, decimal);

      // send approve request
      const { hash: approveHash } = await approveWrite({
        args: [farmingAddress, getContractArgFigure(+stakeAmount, decimal)],
        from: address,
      });

      await waitForTransaction({
        confirmations: 1,
        hash: approveHash,
      });

      const { hash: stakeHash } = await stakeWrite({
        args: [index, getContractArgFigure(+stakeAmount, decimal)],
        from: address,
      });
      await waitForTransaction({
        confirmations: 1,
        hash: stakeHash,
      });
      toast.success('Token Successfully Staked!');
      onHide();
    } catch (e) {
      console.log('Error-handleStake:', e);
      toast.error(e.message);
    } finally {
      setIsStaking(false);
      toast.dismiss(_toast);
      await setReloading((prev) => !prev);
    }

    return false;
  };

  const handleUnstake = async () => {
    const agreementChecked = checkedRef.current.checked;

    if (!agreementChecked) {
      toast.error('Please agree to the Caladex Staking Service Agreement.');
      return false;
    }

    if (!stakeAmount || stakeAmount <= 0) {
      toast.error('Please input stake amount.');
      inputRef.current.classList.add('is-invalid');
      inputRef.current.focus();
      return false;
    }

    if (!stakedAmount || stakeAmount > +stakedAmount) {
      toast.error('Unstake amount should be less than staked amount!');
      inputRef.current.focus();
      return false;
    }

    const _toast = toast.loading('Unstaking token...');
    setIsUnstaking(true);
    try {
      // await unstakeToken(address, +stakeAmount, index, farmingAddress, decimal);

      const { hash: unstakeHash } = await unstakeWrite({
        args: [index, getContractArgFigure(+stakeAmount, decimal)],
        from: address,
      });
      await waitForTransaction({
        confirmations: 1,
        hash: unstakeHash,
      });
      toast.success('Token Successfully Unstaked!');
      onHide();
    } catch (e) {
      console.log('Error-handleStake:', e);
      toast.error(e.message);
    } finally {
      setIsUnstaking(false);
      toast.dismiss(_toast);
      await setReloading((prev) => !prev);
    }

    return false;
  };

  return (
    <Modal
      onHide={onHide}
      show={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{symbol} Farming</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Card>
          <Card.Header className="justify-content-center">
            <div className="row col-sm-12">
              <div className="d-flex flex-sm-column justify-content-between flex-md-row px-0">
                {UNSTAKE_FEES.map((value, index) => (
                  <div
                    className="card"
                    key={`unstake-fee-list-${index}`}
                  >
                    <div className="card-header text-center">
                      <h4 className={`card-title ${value.class} w-100`}>
                        {' '}
                        {` < `} {value.minDays} Days
                      </h4>
                    </div>
                    <div className="card-body text-center">
                      Unstake Fee: <span className="text-white fw-700">{value.unstakeFee}%</span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </Card.Header>
          <Card.Body>
            <div className="row mb-3 align-items-center">
              <div className="col-sm-12">
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <label className="input-group-text text-white  bg-primary">Amount</label>
                  </div>
                  <input
                    type="text"
                    className="form-control text-end"
                    placeholder={`${symbol}`}
                    value={stakeAmount}
                    onChange={(e) => {
                      setStakeAmount(e.target.value);
                    }}
                    ref={inputRef}
                  />
                  <div className="input-group-prepend">
                    <label className="input-group-text text-white  bg-secondary">MAX</label>
                  </div>
                </div>
              </div>
              <div className="col-md-12 mb-3">
                <p className={`mb-1 text-primary`}>
                  Available: {tokenBalance} {symbol}
                  <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip id="available-info">Available token amount in your account to stake.</Tooltip>}
                  >
                    <span className="mx-1 text-info">
                      <i className="fa fa-info-circle"></i>
                    </span>
                  </OverlayTrigger>
                </p>
              </div>
              <div className="col-md-12 mb-3 border-bottom">
                <h4 className="text-white">
                  Limit Amounts
                  <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip id="limit-amount-info">Maximum or minimum token amount you can stake.</Tooltip>}
                  >
                    <span className="mx-1 text-info">
                      <i className="fa fa-info-circle"></i>
                    </span>
                  </OverlayTrigger>
                </h4>
                <div className="col-md-12 d-flex justify-content-between">
                  <h4 className="text-danger">
                    Min:
                    {poolInfo?.minAmount
                      ? new BigNumber(poolInfo?.minAmount).shiftedBy(-decimal).toFixed(3)
                      : ' - '}{' '}
                    {symbol}
                  </h4>
                  <h4 className="text-success">
                    Max:{poolInfo?.maxAmount ?? ' - '} {symbol}
                  </h4>
                </div>
              </div>
              <div className="col-md-12 mb-3 border-bottom">
                <Accordion
                  id="accordion-faq"
                  defaultActiveKey="0"
                  className="accordion"
                >
                  <Card>
                    <Accordion.Toggle
                      as={Card.Header}
                      eventKey="0"
                    >
                      <h4>
                        Farm Period
                        <OverlayTrigger
                          placement="right"
                          overlay={
                            <Tooltip id="period-amount-info">
                              Calculated farming date and end date for zero fee.
                            </Tooltip>
                          }
                        >
                          <span className="mx-1 text-info">
                            <i className="fa fa-info-circle"></i>
                          </span>
                        </OverlayTrigger>
                      </h4>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                      <Card.Body>
                        <div className="row mx-5">
                          <div className="col-sm-2">
                            <div className="d-flex justify-content-center mb-5">
                              <span>
                                <i className="fa fa-circle text-primary"></i>
                              </span>
                              <div className="position-absolute stake-line">
                                <i className="fa fa-arrow-down text-white"></i>
                              </div>
                            </div>
                            <div className="d-flex justify-content-center">
                              <span>
                                <i className="fa fa-circle text-danger"></i>
                              </span>
                            </div>
                          </div>
                          <div className="col-sm-10">
                            <div className="d-flex justify-content-between mb-5">
                              <span>Start Date</span>
                              <span className="text-white">{moment().format('DD MMMM, YYYY, hh:mm:ss')}</span>
                            </div>
                            <div className="d-flex justify-content-between">
                              <span>End Date</span>
                              <span className="text-white">
                                {moment().add(30, 'days').format('DD MMMM, YYYY, hh:mm:ss')}
                              </span>
                            </div>
                          </div>
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              </div>
              <div className="col-md-12 mb-3">
                <div className="d-flex justify-content-between">
                  <span>EST.APY</span>
                  <span className="text-white">{!isEmpty(poolInfo) ? getAPY(poolInfo, chainId) : ' - '}</span>
                </div>
                {/* <div className='d-flex justify-content-between'>
                                    <span>Estimated Interests</span>
                                    <span className='text-success'>0 CAX</span>
                                </div> */}
              </div>
              <div className="col-sm-12">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="flexCheckDefault"
                    ref={checkedRef}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexCheckDefault"
                  >
                    I have read and agree to the <Link to={`/stake`}>Caladex Staking Service Agreement.</Link>
                  </label>
                </div>
              </div>
            </div>
          </Card.Body>
        </Card>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleStake}>Stake</Button>
        <Button
          variant="danger"
          onClick={handleUnstake}
        >
          Unstake
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
