import React, { Component } from 'react';
import { Modal, Button, Card, OverlayTrigger, Tooltip, Accordion } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import * as Yup from 'yup'
import Axios from "axios"
import { LinearProgress } from "@mui/material";
import ReCAPTCHA from "react-google-recaptcha";
import { useFormik } from "formik";
import { useRef } from 'react';
import { BACKEND_API_URL, CHAIN_LIST, HTTP_SUCCESS_CODE, HTTP_SUCCESS_STATUS, MAX_IMAGE_SIZE, RECAPTCHA_KEY } from '../../shared/constants';
import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { atomTargetTokenList } from '../../state/atoms';
import { useContext } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import { isEmpty } from 'lodash';
import { useTargetTokenList } from '../../state/hooks';
import { isValidFileType } from '../../shared/helpers';
import { toast } from 'react-hot-toast';
import { useState } from 'react';


export function TokenListDialog({ onHide, show }) {

    const targetTokenList = useRecoilValue(atomTargetTokenList)

    const { currentUser, authToken, isAuthenticated } = useContext(AuthContext)

    const { targetTokenRefresh } = useContext(AuthContext)

    const [isTokenListing, setIsTokenListing] = useState(false)

    const recaptchaRef = useRef()

    const logoUrlRef = useRef()

    const formik = useFormik({
        initialValues: {
            chain_id: "0x1",
            name: "",
            symbol: "",
            decimal: "",
            pair_type: "DAI",
            address: "",
            website_url: "",
            logo_url: "",
            issuer_name: "",
            email_address: ""
        },
        initialErrors: {
            chain_id: "",
            name: "",
            symbol: "",
            decimal: "",
            pair_type: "",
            address: "",
            website_url: "",
            logo_url: null,
            issuer_name: "",
            email_address: ""
        },
        validationSchema: Yup.object().shape({
            chain_id: Yup.string()
                .oneOf(CHAIN_LIST.map(chain => chain.chainId), "Please valid network.")
                .required("Please enter network."),
            name: Yup.string().min(3, "Token  name must be longer than 3 characters.").required("Please enter token name."),
            symbol: Yup.string().min(3, "Token symbol must be longer than 3 characters.").required("Please enter token symbol."),
            decimal: Yup.number().min(0, "Token decimal must be greater than 0.").required("Please enter token decimal."),
            pair_type: Yup.string().min(3, "Pair token must be longer than 3 characters.").required("Please enter pair token."),
            address: Yup.string().length(42, "Token address must be equal to 42 characters.").required("Please enter token address."),
            logo_url: Yup.mixed().required('File is required')
                .test("is-valid-type", "Not a valid image type", value => isValidFileType(!isEmpty(value) && value, "image")),
            website_url: Yup.string().matches(/^[(https:\/\/)(http:\/\/)]/i, "Please enter full website url.").min(10, "Website url must be greater than 10 characters.").required("Please enter website url."),
            issuer_name: Yup.string().min(3, "Issuer name must be longer than 3 characters.").required("Please enter issuer name."),
            email_address: Yup.string()
                .email("Invalid email address.")
                .required("Please enter email."),
        }),
        handleSubmit: async () => {

        },
        onSubmit: async () => {

            // check if logged in

            if (!isAuthenticated || isEmpty(currentUser) || !authToken) {
                toast.error("Please login to list your business token.")
                return false
            }

            if (logoUrlRef.current.files[0].size > MAX_IMAGE_SIZE) {
                toast.error("File size is limited to be less than 100KB.")
                return false
            }

            // check recaptcha value
            const recaptchaValue = recaptchaRef.current.getValue()

            if (!recaptchaValue) {

                toast.error("Please verify recaptcha.")
                return false                
            }

            const submittedData = new FormData()
            for (let key in formik.values) {
                if (key != "logo_url") submittedData.append(key, formik.values[key])
            }
            submittedData.append('file', logoUrlRef.current.files[0])
            submittedData.append('type', 1)
            submittedData.append('recaptchaToken', recaptchaValue)

            const _toast = toast.loading("Listing token...")
            setIsTokenListing(true)

            try {

                // send listing request
                const listingResult = await Axios.post(`${BACKEND_API_URL}/token/add`, submittedData, { headers: { Authorization: `Bearer ${authToken}` } })

                if (listingResult.status !== HTTP_SUCCESS_STATUS) {
                    toast.dismiss(_toast)
                    toast.error(listingResult?.data?.message)
                    return false
                }

                toast.success("Token Application Successfully Submitted!")

                onHide()

            }

            catch (e) {
                if (e?.response?.data?.message) {
                    console.log("Token-Info-Page@list-token-error:", e?.response?.data?.message)
                    toast.error(e?.response?.data?.message)
                } else {
                    console.error('Token-Info-Page@list-token-error:', e.message)
                    toast.error(e.message)
                }

            }

            finally {
                setIsTokenListing(false)
                toast.dismiss(_toast)
            }

            return false
        },
    })

    useEffect(() => {
        targetTokenRefresh()
    }, [])

    return (
        <Modal
            onHide={onHide}
            show={show}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Token Listing Dialog
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Card>
                    <Card.Header className='row'>
                        <h3 className='text-center text-success'>List Your Token to Caladex And Get Noticed.</h3>
                        <p className='text-center'>( PLEASE USE THIS FORM ONLY IF YOU HAVE ALREADY CREATED TOKEN ON ETHEREUM, POLYGON OR BINANCE SMART CHAIN NETWORK. FOR ASSISTANCE IN TOKEN CREATION SEND EMAIL TO CUSTOMERSERVICE@CALADEX.ORG )</p>
                    </Card.Header>
                    <Card.Body>
                        {formik.isSubmitting && <LinearProgress color='info' />}
                        <div className='row mb-3 align-items-center'>
                            <div className="mb-3 col-xl-12">
                                <label className="form-label text-white">Network</label>
                                <select className="form-control"
                                    id="chain_id"
                                    name="chain_id"
                                    value={formik.values.chain_id}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                >
                                    {CHAIN_LIST.map((chain, index) => <option key={`token-listing-network-${index}`} value={chain.chainId}>{chain.chainName}</option>)}
                                    <option value="">Ethereum</option>
                                    <option value="">Polygon</option>
                                    <option value="">Binance</option>
                                </select>
                                {formik.touched.chain_id && formik.errors.chain_id ? (
                                    <div className="text-danger">
                                        {formik.errors.chain_id}
                                    </div>
                                ) : ``}
                            </div>
                            <div className="mb-3 col-xl-12">
                                <label className="form-label text-white">
                                    Token Name{" "}
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="KAMHOL"
                                    id="name"
                                    name="name"
                                    value={formik.values.name}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.name && formik.errors.name ? (
                                    <div className="text-danger">
                                        {formik.errors.name}
                                    </div>
                                ) : ``}
                            </div>
                            <div className="mb-3 col-xl-12">
                                <label className="form-label text-white">
                                    Token Symbol{" "}
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="KAMHOL-POL"
                                    id="symbol"
                                    name="symbol"
                                    value={formik.values.symbol}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.symbol && formik.errors.symbol ? (
                                    <div className="text-danger">
                                        {formik.errors.symbol}
                                    </div>
                                ) : ``}
                            </div>
                            <div className="mb-3 col-xl-12">
                                <label className="form-label text-white">
                                    Decimal{" "}
                                </label>
                                <input
                                    type="number"
                                    className="form-control"
                                    placeholder="18"
                                    id="decimal"
                                    name="decimal"
                                    value={formik.values.decimal}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.decimal && formik.errors.decimal ? (
                                    <div className="text-danger">
                                        {formik.errors.decimal}
                                    </div>
                                ) : ``}
                            </div>
                            <div className="mb-3 col-xl-12">
                                <label className="form-label text-white">Trade Pair Token</label>
                                <select className="form-control"
                                    id="pair_type"
                                    name="pair_type"
                                    value={formik.values.pair_type}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                >
                                    {!isEmpty(targetTokenList) && targetTokenList.filter(token => token.chain_id == formik.values.chain_id).map((token, index) => (
                                        <option value={token.symbol} key={`token-list-pair-${index}`}>{token.symbol}</option>
                                    ))}
                                </select>
                                {formik.touched.pair_type && formik.errors.pair_type ? (
                                    <div className="text-danger">
                                        {formik.errors.pair_type}
                                    </div>
                                ) : ``}
                            </div>
                            <div className="mb-3 col-xl-12">
                                <label className="form-label text-white">
                                    Token Address{" "}
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="0x..."
                                    id="address"
                                    name="address"
                                    value={formik.values.address}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.address && formik.errors.address ? (
                                    <div className="text-danger">
                                        {formik.errors.address}
                                    </div>
                                ) : ``}
                            </div>
                            <div className="mb-3 col-xl-12">
                                <label className="form-label text-white">
                                    Public Website{" "}
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="https://makerdao.com"
                                    id="website_url"
                                    name="website_url"
                                    value={formik.values.website_url}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.website_url && formik.errors.website_url ? (
                                    <div className="text-danger">
                                        {formik.errors.website_url}
                                    </div>
                                ) : ``}
                            </div>
                            <div className="mb-3 col-xl-12">
                                <label className="form-label text-white">
                                    Logo PNG{" "}
                                </label>
                                <div className="file-upload-wrapper" data-text="Logo PNG">
                                    <input
                                        type="file"
                                        className="file-upload-field"
                                        id="logo_url"
                                        name="logo_url"
                                        ref={logoUrlRef}
                                        value={formik.values.logo_url}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                                {formik.touched.logo_url && formik.errors.logo_url ? (
                                    <div className="text-danger">
                                        {formik.errors.logo_url}
                                    </div>
                                ) : ``}
                            </div>
                            <div className="mb-3 col-xl-12">
                                <label className="form-label text-white">
                                    Name Token Issuer{" "}
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="John Doe"
                                    id="issuer_name"
                                    name="issuer_name"
                                    value={formik.values.issuer_name}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.issuer_name && formik.errors.issuer_name ? (
                                    <div className="text-danger">
                                        {formik.errors.issuer_name}
                                    </div>
                                ) : ``}
                            </div>
                            <div className="mb-3 col-xl-12">
                                <label className="form-label text-white">
                                    Email Address{" "}
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="great@token.com"
                                    id="email_address"
                                    name="email_address"
                                    value={formik.values.email_address}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.email_address && formik.errors.email_address ? (
                                    <div className="text-danger">
                                        {formik.errors.email_address}
                                    </div>
                                ) : ``}
                            </div>
                            <div className="d-flex justify-content-center mb-4">
                                <ReCAPTCHA
                                    ref={recaptchaRef}
                                    sitekey={RECAPTCHA_KEY}
                                    onChange={() => { }}
                                />
                            </div>
                        </div>
                    </Card.Body>
                </Card>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onHide} className={`btn-danger`}>Close</Button>
                {!formik.isSubmitting && !isTokenListing ? <Button onClick={formik.handleSubmit}>List</Button> : <i className="fa fa-spinner text-primary fa-spin fa-3x fa-fw"></i>}
            </Modal.Footer>
        </Modal >
    );
}
