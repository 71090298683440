import React from "react";
import "react-perfect-scrollbar/dist/css/styles.css";
import "react-rangeslider/lib/index.css";
import Footer2 from "../layout/footer2";
import Header2 from "../layout/header2";
import { MoonPayBuyWidget } from '@moonpay/moonpay-react';

function OnRamp() {

    return (
        <>
            <Header2 activePage={`onRamp`} />
            <div className="moonpay-widget">
                <MoonPayBuyWidget
                    variant="embedded"
                    baseCurrencyCode="usd"
                    baseCurrencyAmount="100"
                    defaultCurrencyCode="eth"
                    theme="dark"
                    colorCode='#28e2e2'
                    visible={true}
                    debug="true"
                />
            </div>
            <Footer2 />
        </>
    );
}

export default OnRamp;
