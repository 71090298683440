import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext';
import { useAccountInfo } from '../../state/hooks';
import { hasIn, isEmpty, isUndefined } from 'lodash';
import { BACKEND_API_URL, HTTP_SUCCESS_STATUS, USER_STATUS } from '../../shared/constants';
import { toast } from 'react-hot-toast';
import { useState } from 'react';
import Axios from "axios"


function AccountSubmenu({ active, data }) {

    const { currentUser, isAuthenticated, authToken } = useContext(AuthContext)

    const { refetch: userInfoRefetch } = useAccountInfo(authToken, currentUser?.id)

    const [isVerifying, setVerifying] = useState(false)
    const [isCanceling, setCanceling] = useState(false)

    const handleVerify = async () => {

        if (!isAuthenticated || isEmpty(currentUser) || !authToken) {
            toast.error("Please login to verify account.")
            return false
        }

        if (Object.values(data?.user ?? {}).find(value => !value)) {
            toast.error("Please fill all fields to verify.")
            return false
        }
        setVerifying(true)
        const _toast = toast.loading("Verifying account...")

        try {

            const verifyResult = await Axios.post(`${BACKEND_API_URL}/user/apply-verify`, { id: currentUser?.id }, { headers: { Authorization: `Bearer ${authToken}` } })
            if (verifyResult.status != HTTP_SUCCESS_STATUS) {
                toast.dismiss(_toast)
                console.log("Account-Sub-Menu@apply-verify-error:", verifyResult?.data?.message)
                setVerifying(false)
                return false
            }

            toast.dismiss(_toast)
            toast.success("Verification Successfully Submitted!")
            userInfoRefetch()
        }

        catch (e) {
            console.log("apply-verify", e)
            toast.dismiss(_toast)
            if (e?.response?.data?.message) {
                console.log("Account-Sub-Menu@apply-verify-error:", e?.response?.data?.message)
                toast.error(e?.response?.data?.message)
            } else {
                console.error('Account-Sub-Menu@apply-verify-error:', e.message)
                toast.error(e.message)
            }
        }
        setVerifying(false)
        return false

    }

    const handleCancelVerify = async () => {

        if (!isAuthenticated || isEmpty(currentUser) || !authToken) {
            toast.error("Please login to verify account.")
            return false
        }

        setCanceling(true)
        const _toast = toast.loading("Canceling verification...")

        try {

            const cancelResult = await Axios.post(`${BACKEND_API_URL}/user/cancel-verify`, { id: currentUser?.id }, { headers: { Authorization: `Bearer ${authToken}` } })
            if (cancelResult.status != HTTP_SUCCESS_STATUS) {
                toast.dismiss(_toast)
                throw new Error(cancelResult.data.message)
            }

            toast.dismiss(_toast)
            toast.success("Verification Successfully Cancelled!")
            userInfoRefetch()
        }

        catch (e) {
            console.log("cancel-verify", e)
            toast.dismiss(_toast)
            if (e?.response?.data?.message) {
                console.log("Account-Sub-Menu@cancel-verify-error:", e?.response?.data?.message)
                toast.error(e?.response?.data?.message)
            } else {
                console.error('Account-Sub-Menu@cancel-verify-error:', e.message)
                toast.error(e.message)
            }
        }
        setCanceling(false)
        return false

    }

    return (
        <>
            <ul className="d-flex">
                <li className="nav-item">
                    <Link to={"/account-overview"} className={`nav-link ${active == 'overview' ? `active` : ``}`}>
                        <i className="mdi mdi-bullseye"></i>
                        <span>Overview</span>
                    </Link>
                </li>
                <li className="nav-item">
                    <Link to={"/account-deposit"} className={`nav-link ${active == 'deposit' ? `active` : ``}`}>
                        <i className="mdi mdi-heart"></i>
                        <span>Deposit</span>
                    </Link>
                </li>
                <li className="nav-item">
                    <Link to={"/account-withdraw"} className={`nav-link ${active == 'withdraw' ? `active` : ``}`}>
                        <i className="mdi mdi-pentagon"></i>
                        <span>Withdraw</span>
                    </Link>
                </li>
                {!isUndefined(data) && data?.user?.status == USER_STATUS.APPROVED && <li className="nav-item">
                    <Link to={"#"} className="nav-link text-primary verify-outlined" onClick={handleVerify} disabled={isVerifying}>
                        <i className="mdi mdi-check"></i>
                        <span>Apply for verification</span>
                    </Link>
                </li>}
                {!isUndefined(data) && data?.user?.status == USER_STATUS.VERIFYING && <li className="nav-item" disabled={isCanceling}>
                    <Link to={"#"} className="nav-link text-danger" onClick={handleCancelVerify}>
                        <i className="mdi mdi-check"></i>
                        <span>Cancel verification</span>
                    </Link>
                </li>}
                {/* <li className="nav-item">
                    <Link to={"./account-api"} className="nav-link">
                        <i className="mdi mdi-database"></i>
                        <span>API</span>
                    </Link>
                </li>
                <li className="nav-item">
                    <Link to={"./account-affiliate"} className="nav-link">
                        <i className="mdi mdi-diamond"></i>
                        <span>Affiliate</span>
                    </Link>
                </li> */}
            </ul>
        </>
    )
}

export default AccountSubmenu;