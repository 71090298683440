import React, { useContext } from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ScrollspyNav from "react-scrollspy-nav";
import { AuthContext } from '../../contexts/AuthContext';
import { isEmpty } from 'lodash';
import { BrowserView, MobileOnlyView, MobileView } from 'react-device-detect';


function Header1() {

    const { isAuthenticated, currentUser } = useContext(AuthContext)

    return (
        <>
            <div className="header">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="navigation">
                                <Navbar bg="light" expand="lg">
                                    <BrowserView>
                                        <Link className="navbar-brand" to={"/"}><img src={require('./../../images/logo.png')} alt="" /></Link>
                                    </BrowserView>
                                    <MobileOnlyView>
                                        <Link className="navbar-brand" to={"/"}><img src={require('./../../images/logo-spin.png')} width={`50px`} alt="" /></Link>
                                    </MobileOnlyView>
                                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                                    <Navbar.Collapse>
                                        {/* <ScrollspyNav
                                            scrollTargetIds={["home", "price", "portfolio", "testimonial", "app", "blog",]}
                                            offset={100}
                                            activeNavclassName="active"
                                            scrollDuration="500"
                                            headerBackground="true"
                                        > */}
                                        <Nav className="ms-auto">
                                            <Nav.Item><Nav.Link className="nav-Nav.link" href="/">Home</Nav.Link></Nav.Item>
                                            <Nav.Item><Nav.Link className="nav-Nav.link" href="/exchange/ethereum/cax_dai">Exchange</Nav.Link></Nav.Item>
                                            {isAuthenticated && <Nav.Item><Nav.Link className="nav-Nav.link" href="/on-ramp">Buy Crypto </Nav.Link></Nav.Item>}
                                            <Nav.Item><Nav.Link className="nav-Nav.link" href="/farm">Earn/Stake </Nav.Link></Nav.Item>
                                            <Nav.Item><Nav.Link className="nav-Nav.link" href="/token-info">Token Info Base</Nav.Link></Nav.Item>
                                            <Nav.Item><Nav.Link className="nav-Nav.link" href="/blogs">Blog</Nav.Link></Nav.Item>
                                            <MobileView>
                                                {!isAuthenticated && isEmpty(currentUser) && <Link className="btn btn-primary ms-3" to={'/signin'}>Sign in</Link>}
                                            </MobileView>
                                        </Nav>
                                        {/* </ScrollspyNav> */}

                                    </Navbar.Collapse>
                                    <BrowserView>
                                        <div className="signin-btn">
                                            {/* <Link to={'./dashboard'}>Dashboard</Link> */}
                                            {!isAuthenticated && isEmpty(currentUser) && <Link className="btn btn-primary ms-3" to={'/signin'}>Sign in</Link>}
                                        </div>
                                    </BrowserView>
                                </Navbar>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Header1;