import React from 'react'
import Header2 from '../layout/header2'
import Sidebar from '../layout/sidebar'
import PageTitle from '../element/page-title'
import { useParams } from 'react-router-dom'
import { isUndefined } from 'lodash'
import { useNewsList } from '../../state/hooks'
import { BACKEND_FILE_URL } from '../../shared/constants'

export const News = () => {

    const { id } = useParams()

    const { data: newsList } = useNewsList()
    return (
        <>
            <Header2 />
            <div className="content-body">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            {!isUndefined(newsList) && <div className="card">
                                <div className="card-header">
                                    <h2 className="card-title fs-2 text-center w-100">{newsList.find(value => value._id === id).title}</h2>
                                </div>
                                <div className="card-body">
                                    <div className="row align-items-center">
                                        <div className="col-xl-8 mx-auto">
                                            <img src={`${BACKEND_FILE_URL}/${newsList.find(value => value._id === id).header_img}`} alt="" className="img-fluid d-block mx-auto my-3" />
                                        </div>
                                        <div className="col-xl-10 mx-auto">
                                            <p className='fs-4'>{newsList.find(value => value._id === id).content}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}