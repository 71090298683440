import axios from "axios";

export const searchToken = async (
  chainId,
  contractAddr,
  decimals,
) => {
  const chain = bindChain(chainId);

  try {
    const result = await axios.get(
      `https://api.coingecko.com/api/v3/coins/${chain}/contract/${contractAddr}`,
    );
    if (result.data) {
      const token = result.data;
      return true
    }
    return undefined;
  } catch (error) {
    throw new Error(`Could find token.`);
  }
}

/**
 * Bind chain id with its chain name compatible with coingecko.
 * @param chainId
 * @returns
 */
export const bindChain = (
  chainId,
) => {
  if (chainId === '0x56') return 'binance-smart-chain';
  if (chainId === '0x137') return 'polygon-pos';
  return 'ethereum';
}

export const fetchTickerTokenUsd = async (symbol) => {
  try {
    const result = await axios.get(
      `https://api.coingecko.com/api/v3/simple/price?ids=${symbol}&vs_currencies=usd`,
    );
    return result.data[symbol].usd;
  } catch (error) {
    return undefined
  }
}

export const fetchCoinbaseTokenUsd = async (symbol) => {
  try {
    const result = await axios.get(
      `https://api.coinbase.com/v2/exchange-rates?currency=${symbol}`,
    );
    return result.data.data.rates["USD"];
  } catch (error) {
    return undefined
  }
}

export const getTokenPrice = async (symbol) => {
  let usdPrice = undefined;
  // get token price from coingecko api
  try {
    usdPrice = await fetchTickerTokenUsd(symbol.toLowerCase())
    if (usdPrice === undefined) {
      // get token price from coinbase api
      usdPrice = await fetchCoinbaseTokenUsd(symbol)
    }
  }

  catch (e) {
    console.log('get-token-price:', e)
  }

  return !isNaN(+usdPrice) ? +usdPrice : 0
}

export const fetchGeckoPrice = async () => {
  try {
    const result = await axios.get(
      // `https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=render-token%2Cverasity%2Cinjective-protocol%2Cholotoken%2Carbitrum%2Cchainlink%2Cethereum-name-service%2Cwrapped-bitcoin%2Csolidus-aitech%2Cnuls%2Cthe-open-network%2Cbinancecoin%2Cbinance-peg-filecoin%2Cvechain%2Cbinance-peg-avalanche%2Cethereum-wormhole%2Cbinance-peg-xrp%2Ciota%2Ctron-bsc%2Cwrapped-solana%2Capecoin%2Cbinance-peg-cardano%2Cbinance-peg-polkadot%2C&order=market_cap_desc&per_page=100&page=1&sparkline=true&locale=en`
      `https://pro-api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=render-token,verasity,injective-protocol,holotoken,arbitrum,chainlink,ethereum-name-service,wrapped-bitcoin,solidus-aitech,nuls,the-open-network,binancecoin,binance-peg-filecoin,vechain,binance-peg-avalanche,ethereum-wormhole,binance-peg-xrp,iota,tron-bsc,wrapped-solana,apecoin,binance-peg-cardano,binance-peg-polkadot&order=market_cap_desc&per_page=100&page=1&sparkline=true&locale=en&x_cg_pro_api_key=CG-w9KjR3DD7xNKsP4cA833YyvC`

    ); 
    return result.data;
  } catch (error) {
    return undefined
  }
}