import React, { } from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { useContext } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import { isEmpty } from 'lodash';
import { setRefreshToken } from '../../shared/helpers';
import { BrowserView, MobileOnlyView } from 'react-device-detect';
import { Web3Button } from '@web3modal/react';

const ProfileToggle = React.forwardRef(({ children, onClick }, ref) => (
  <div
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    <div className="profile_log">
      <div className="user">
        <span className="thumb">
          <i className="mdi mdi-account"></i>
        </span>
        <span className="arrow">
          <i className="la la-angle-down"></i>
        </span>
      </div>
    </div>
  </div>
));

function Header2({ activePage }) {

  const history = useHistory();

  const { setAuthToken, currentUser, isAuthenticated, setAuthenticated, setCurrentUser, authToken, tokenPriceRefresh } =
    useContext(AuthContext);

  const handleLogout = (e) => {
    e.preventDefault();

    setAuthToken('');
    setRefreshToken('');
    setCurrentUser({});
    setAuthenticated(false);

    history.push('/signin');
    return false;
  };

  return (
    <>
      <div className="header dashboard">
        <div className={`${activePage === `exchange` ? `container-fluid px-md-5` : 'container'}`}>
          <div className="row">
            <div className="col-xl-12">
              <div className={`navigation ${activePage === 'exchange' ? `px-md-3` : ``} justify-content-between`}>
                <Navbar bg="light" expand="lg">
                  <BrowserView>
                    <Link
                      className="navbar-brand"
                      to={'/'}
                    >
                      <img
                        src={require('./../../images/logo.png')}
                        alt=""
                      />
                    </Link>
                  </BrowserView>
                  <MobileOnlyView>
                    <Link
                      className="navbar-brand"
                      to={'/'}
                    >
                      <img
                        src={require('./../../images/logo-spin.png')}
                        width={`50px`}
                        alt=""
                      />
                    </Link>
                  </MobileOnlyView>
                  <Navbar.Toggle aria-controls="basic-navbar-nav" />
                  <Navbar.Collapse>
                    <Nav className="ms-auto">
                      <Nav.Item><Nav.Link className={`nav-Nav.link`} href="/">Home</Nav.Link></Nav.Item>
                      <Nav.Item><Nav.Link className={`nav-Nav.link ${activePage === 'exchange' ? `active` : ``}`} href="/exchange/ethereum/cax_dai">Exchange</Nav.Link></Nav.Item>
                      {isAuthenticated && <Nav.Item><Nav.Link className={`nav-Nav.link ${activePage === 'onRamp' ? `active` : ``}`} href="/on-ramp">Buy Crypto </Nav.Link></Nav.Item>}
                      <Nav.Item><Nav.Link className={`nav-Nav.link ${activePage === 'farm' ? `active` : ``}`} href="/farm">Earn/Stake </Nav.Link></Nav.Item>
                      <Nav.Item><Nav.Link className={`nav-Nav.link ${activePage === 'token-info' ? `active` : ``}`} href="/token-info">Token Info Base</Nav.Link></Nav.Item>
                      {isAuthenticated && <>
                        <Nav.Item><Nav.Link className={`nav-Nav.link ${activePage === 'account-overview' ? `active` : ``}`} href="/account-overview">Account</Nav.Link></Nav.Item>
                      </>}
                      {activePage === `exchange` && <Nav.Item><Nav.Link className={`nav-Nav.link ${activePage === 'blogs' ? `active` : ``}`} href="/blogs">Blog</Nav.Link></Nav.Item>}
                      {!isAuthenticated && isEmpty(currentUser) ? <Nav.Item><Nav.Link className={`nav-Nav.link`} href="/signin">Sign in</Nav.Link></Nav.Item> : <Nav.Item><Nav.Link className={`nav-Nav.link`} href="#" onClick={handleLogout}>Sign out</Nav.Link></Nav.Item>}
                    </Nav>
                    {/* </ScrollspyNav> */}
                    <div className="d-flex flex-column flex-md-row my-2 my-md-0 align-items-center float-md-right">
                      <div className="signin-btn">
                        <Web3Button />
                      </div>
                    </div>
                  </Navbar.Collapse>

                </Navbar>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header2;
