import { trim } from 'lodash';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { toast } from 'react-hot-toast';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import * as Yup from 'yup'
import { BACKEND_API_URL, HTTP_SUCCESS_STATUS } from '../../shared/constants';
import Axios from "axios"
import { CircularProgress } from '@mui/material';

function Reset() {

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState('')
    const [isResetting, setResetting] = useState(false)
    const history = useHistory()

    const handleSubmitReset = async () => {

        const payloadSchema = Yup.object().shape({
            email: Yup.string().email("Please input valid email address").max(255, "Email address should be shorter than 255.").required("Please input Email address."),
            password: Yup.string().min(8, "Password should be longer than 8 characters").required("Please input password!")
        })

        let validationResult = ""
        await payloadSchema.validate({ email, password })
            .catch((err) => {
                validationResult = err.message;
                return false
            });

        if (validationResult) {
            toast.error(validationResult)
            return false
        }

        const _toast = toast.loading("Resetting Password...")
        setResetting(true)

        try {

            const forgotPasswordResult = await Axios.post(`${BACKEND_API_URL}/user/reset-password`, { email, password })
            if (forgotPasswordResult.status != HTTP_SUCCESS_STATUS) {
                toast.dismiss(_toast)
                console.log("Reset-Password-Page@change-password-error:", forgotPasswordResult?.data?.message)
                setResetting(false)
                return false
            }

            toast.dismiss(_toast)
            toast.success("Reset password email successfully sent!")
            history.push(`/signin`)
        }
        catch (e) {
            toast.dismiss(_toast)
            console.log('email-change', e)
            if (e?.response?.data?.message) {
                console.log("Reset-Password@reset-password-error:", e?.response?.data?.message)
                toast.error(e?.response?.data?.message)
            } else {
                console.error('Reset-Password@reset-password-error:', e.message)
                toast.error(e.message)
            }
        }
        finally {
            setResetting(false)
        }

        return false
    }

    return (
        <>
            <div className="authincation section-padding">
                <div className="container h-100">
                    <div className="row justify-content-center h-100 align-items-center">
                        <div className="col-xl-5 col-md-6">
                            <div className="mini-logo text-center my-5">
                                <Link to={"/"}><img src={require("../../images/logo.png")} alt="" width={`300px`} /></Link>
                            </div>
                            <div className="auth-form card">
                                <div className="card-header justify-content-center">
                                    <h4 className="card-title">Reset password</h4>
                                </div>
                                <p className='text-center text-danger'>The reset password link will be available only for 10 min.</p>
                                <div className="card-body">
                                    <form>
                                        <div className="mb-3">
                                            <label>Email</label>
                                            <input type="email" className="form-control" value={email} onChange={(e) => { setEmail(e.target.value) }} />
                                        </div>
                                        <div className="mb-3">
                                            <label>New Password: </label>
                                            <input type="password" className="form-control" value={password} onChange={(e) => { setPassword(e.target.value) }} />
                                        </div>
                                        <div className="text-center">
                                            {!isResetting ? <Button className="btn btn-success btn-block" onClick={handleSubmitReset}>Reset</Button> : <CircularProgress />}
                                        </div>
                                    </form>
                                    <div className="new-account mt-3">
                                        <p className="mb-1">Don't Received? </p>
                                        <Link className="text-primary" to={"/reset"}>Resend </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Reset;