import React, { useState } from "react";
import { Link } from "react-router-dom";
import Footer2 from "../layout/footer2";
import Footer1 from "../layout/footer1";
import Header2 from "../layout/header2";
import Sidebar from "../layout/sidebar";
import Bottom from "../element/bottom";

export const AboutUs = () => {

    return (
        <>
            <Header2 />
            <div className="content-body" style={{ marginTop: '45px' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-header fw-bold">
                                    <h3>
                                        About Us
                                    </h3>
                                </div>
                                <div className="card-body">
                                    <div className="legal-doc">
                                        <span>
                                        We have been running traditional companies for decades. Companies that have
                                        actual offices, stores, production facilities, warehouses and so forth. Traditional 
                                        businesses who provide a product or service in exchange for monetary 
                                        compensation. Our companies are all active in the Real Estate, Construction and 
                                        Chemical Industry, so why would we launch a Crypto and Token exchange platform? 
                                        The answer to that is pretty simple. We studied the ‘nuts and bolts’ of Blockchain 
                                        and saw some core advantages that appealed to us. Decentralization, cryptographic
                                        security, transparency, and immutability. The ability to create tokens with value 
                                        related to our business revenue and existing assets and the simple observation that
                                        the current tokens listed on the crypto market aggregator websites are 99.9% tech 
                                        companies.
                                        <br/>
                                        <br/>
                                        <h4>Our Mission</h4>
                                        We believe that a simple practical approach combined with a robust design and 
                                        some creative use of technology can make complicated ideas more understandable,
                                        and the services we provide through Caladex more enjoyable.
                                        <br/>
                                        <br/>
                                        <h4>Our Mission</h4>
                                        Convince other traditional companies like us, with good track records, to tokenize 
                                        their innovative business ideas and projects and list their token on our platform.
                                        <br/>
                                        <br/>
                                        <h4>Why Caladex?</h4>
                                        We are originally from the Caribbean, from a little island called Aruba, which is 
                                        better known for her beautiful clear ocean and white beaches than for any 
                                        significant contribution to the Crypto Industry. Our island is home to over forty 
                                        nationalities and host to a variety of cultures. We are Caribbean, South American, 
                                        American, Asian, European and the list goes on. We thought Caladex would be and 
                                        appropriate name due to our geographical location and to send a message that 
                                        crypto has no boundaries and that development of a crypto exchange with 
                                        Caribbean roots is now a plain fact and not just an idea.
                                        <br/>
                                        <br/>
                                        <h4>Caladex Founder</h4>
                                        Antony H.P. Kamermans is a Caribbean born serial entrepreneur with a passion for 
                                        cryptocurrencies. A veteran NASDAQ and Crypto trader who traded on a variety of 
                                        crypto exchanges over the last decade. His vision was to create a crypto exchange 
                                        that would be secure, accessible, user friendly and easy to understand, especially 
                                        for new crypto users. Simple limit and market orders, easy conversion tables, online
                                        wallet staking and a secure, but practical approach to both deposits and 
                                        withdrawals. Thes are just a few concepts you will find on the Caladex platform once
                                        you sign up and all features become available. The existing platform and further 
                                        development are in the hands of freelance developers that were carefully selected 
                                        and share the same practical approach to crypto trading. These are full stack 
                                        blockchain developers, blockchain mentors, back -end developers, solidity experts, 
                                        smart contract and NFT developers and the list goes on.
                                        We hope you enjoy our platform as much as we do and become an active member 
                                        of our community because Caladex was developed by the community for the 
                                        community.
                                        <br/>
                                        <br/>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >

            <Bottom />
            <Footer1 />
        </>
    )
}