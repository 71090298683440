import { Route } from "react-router-dom"
import { AuthContext } from "../../contexts/AuthContext"
import { useContext } from "react"
import { CircularProgress } from "@mui/material"
import React from 'react'
import AccountOverview from "../pages/account-overview"
import { getRefreshToken } from "../../shared/helpers"

export const ProtectedRoute = ({ component, path }) => {

    const { currentUser, isAuthenticated, authToken } = useContext(AuthContext)

    const refreshToken = getRefreshToken()
    return (
        isAuthenticated && currentUser && (authToken || refreshToken) ? <Route path={path} component={component} /> : <div className="d-flex justify-content-center align-items-center w-100" style={{ minHeight: "80vh" }}><CircularProgress /></div>
    )
}