import React from 'react'
import Header2 from '../layout/header2'
import Sidebar from '../layout/sidebar'
import { Link } from 'react-router-dom'
import { useNewsList } from '../../state/hooks'
import { isUndefined } from 'lodash'
import { BACKEND_FILE_URL } from '../../shared/constants'

export const AllNews = () => {

    const { data: newsList } = useNewsList()

    return (
        <>
            <Header2 activePage={`blogs`} />
            <div className="content-body">
                <div className="container">
                    <div className="row py-lg-5 justify-content-center">
                        <div className="col-xl-7">
                            <div className="section-heading text-center">
                                <h2>Our Caladex Blogs</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {!isUndefined(newsList) && newsList.map(value => (
                            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6">
                                <div className="demo_img">
                                    <div className="row">
                                        <div className="col">
                                            <Link to={`/blog/${value._id}`}>
                                                <div className="img-wrap">
                                                    <img src={`${BACKEND_FILE_URL}/${value.header_img}`} alt="" className="img-fluid" />
                                                </div>
                                            </Link>
                                        </div>

                                    </div>
                                    <h4>{value.title}</h4>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}