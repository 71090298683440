import React, { } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useCustomerStoryList } from '../../state/hooks';
import { isEmpty } from 'lodash';
import { CircularProgress } from '@mui/material';
import { ImageContainer } from '../pages/exchange';
import { BACKEND_FILE_URL } from '../../shared/constants';
import { Fragment } from 'react';



function Testimonial() {

    const { data: customerStoryData, isFetched } = useCustomerStoryList()

    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };
    return (
        <>
            <Slider {...settings}>
                {isFetched ? !isEmpty(customerStoryData) && customerStoryData.map((story, index) => (
                    <Fragment key={`customer-review-${index}`}>
                        <div className="row align-items-center" style={{ "display": "flex!important" }} >
                            <div className="col-xl-6 col-lg-6">
                                <div className="customer-img">
                                    <ImageContainer className="img-fluid" src={`${BACKEND_FILE_URL}/${story.header_img}`} alt="" />
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6">
                                <div className="customer-review">
                                    {/* <img className="img-fluid" src={require('./../../images/brand/2.webp')} alt="" /> */}
                                    <p>{story.content}</p>
                                    <div className="customer-info">
                                        <h6>{story.user_name}</h6>
                                        <p>{story.user_role}, {story.company}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fragment>
                )) : <div className='d-flex justify-content-center'><CircularProgress /></div>}
            </Slider>
        </>
    )
}

export default Testimonial;