import React from 'react'
import { useEffect } from "react"
import { useState } from "react"

export const WalletContext = React.createContext({
    isConnected: false,
    walletAddress: ""
})

export const WalletContextProvider = ({ children }) => {
    
    const [isConnected, setIsConnected] = useState(false)
    const [walletAddress, setWalletAddress] = useState("")
    const [gasFee, setGasFee] = useState(0)    

    useEffect(() => {
        
    })

    return (
        <WalletContext.Provider value={{
            isConnected,
            setIsConnected,
            walletAddress,
            setWalletAddress,
            gasFee,
            setGasFee
        }}>
            {children}
        </WalletContext.Provider>
    )
}