import React, { useState } from "react";
import { Link } from "react-router-dom";
import Footer2 from "../layout/footer2";
import Footer1 from "../layout/footer1";
import Header2 from "../layout/header2";
import Sidebar from "../layout/sidebar";
import Bottom from "../element/bottom";

export const PrivacyPolicy = () => {

    const textList1 = [
        {description: 'We will not sell or give away your name, mail address, phone number, email address, credit card number or any other information to anyone.', key: 1},
        {description: 'We will use state-of-the-art security measures to protect your information from unauthorized users.', key: 2},
        {description: 'To help you quickly find services or information.', key: 3},
        {description: 'To help us create content most relevant to you.', key: 4},
        {description: 'To alert you to product upgrades, exclusive offers, updated information, and other new services from Caladex', key: 5}
    ];
    const textList2 = [
        {description: 'conform to the edicts of the law or comply with legal process served on Caladex or the site.', key: 1},
        {description: 'protect and defend the rights or property of Caladex and its family of web sites.', key: 2},
        {description: 'act in urgent circumstances to protect the personal safety of users of Caladex its web sites.', key: 3}
    ];

    const listBeforeNotice = textList1.slice(0, 2);
    const listAfterNotice = textList1.slice(2);

    return (
        <>
            <Header2 />
            <div className="content-body" style={{ marginTop: '45px' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-header fw-bold">
                                    <h3>
                                        Privacy policy
                                    </h3>
                                </div>
                                <div className="card-body">
                                    <div className="legal-doc">
                                        <span>
                                        The Internet is an amazing tool. With only a few mouse-clicks, you can follow the 
                                        news, look up facts, buy goods and services, and communicate with others from 
                                        around the world. It is important to Caladex to help our customers retain their 
                                        privacy when they take advantage of all the Internet has to offer. Your privacy is 
                                        not only important to you but also to us. So, we will protect the information you 
                                        share with us. To protect your privacy, Caladex follows different principles in 
                                        accordance with world-wide practices for customer privacy and data protection.
                                        <br/>
                                        <br/>
                                        <ol>
                                            {listBeforeNotice.map((text, index) => {
                                                return (
                                                    <li key={text.key}>{index === 0 ? "a)" : "b)"} {text.description}</li>
                                                );
                                            })}
                                        </ol>
                                        <br/>
                                        <h4>NOTICE</h4>
                                        We use your Personal Information for four primary purposes: 
                                        To make the site easier for you to use by not having to enter information more  
                                        than once.
                                        <br/>
                                        <br/>
                                        <ol>
                                            {listAfterNotice.map((text, index) => {
                                                return (
                                                    <li key={text.key}>{String.fromCharCode(99 + index)}) {text.description}</li>
                                                );
                                            })}
                                        </ol>
                                        <br/>
                                        <h4>CONSENT</h4>
                                        If you choose not to register or provide personal information, you can still use our  
                                        platform fully, but you will not be able to access areas that require registration,  
                                        such as the purchase of crypto with fiat. If you do not want us to communicate  
                                        with you about other offers regarding Caladex products, programs, events, or  
                                        services by e-mail, you may select the option stating that you do not wish to  
                                        receive marketing messages from Caladex. 
                                        <br/>
                                        <br/>
                                        <h4>ACCESS</h4>
                                        We will provide you with the means to ensure that your personal information is  
                                        correct and current. You may review and update this information at any time.
                                        <br/>
                                        <br/>
                                        <h4>SECURITY</h4>
                                        Caladex has taken strong measures to protect the security of your personal  
                                        information and to ensure that your choices for its intended use are honored. We  
                                        take strong precautions to protect your data from loss, misuse, unauthorized  
                                        access or disclosure, alteration, or destruction. We guarantee your transactions to  
                                        be safe and secure. When you place orders or access your personal account  
                                        information, you are utilizing a secure server software SSL, which encrypts your  
                                        personal information before it is sent over the Internet. SSL is one of the safest  
                                        encryption technologies available. Caladex strictly protects the security of your  
                                        personal information and honors your choices for their intended use. We carefully  
                                        protect your data from loss, misuse, unauthorized access or disclosure, alteration,  
                                        or destruction. Your personal information is never shared outside the company  
                                        without your permission. Inside the company, data is stored in passwordcontrolled 
                                        servers with limited access. Your information may be stored and processed in The 
                                        Czech Republic or any other country where Caladex, its subsidiaries, affiliates,  
                                        or agents are located. You also have a significant role in protecting your  
                                        information. No one can see or edit your personal information without knowing  
                                        your username and password, so do not share these with others. If for some  
                                        reason you believe Caladex has not adhered to these principles, please notify us  
                                        by e-mail at customerservice@caladex.com and we will do our best to determine  
                                        and correct the problem promptly. Be certain the words privacy policy are  
                                        mentioned in your email. Caladex will disclose your personal information, without 
                                        notice, only if required to do so by law or in the good faith belief that such action is 
                                        necessary to: 
                                        <br/>
                                        <br/>
                                        <ol>
                                            {textList2.map((text2, index) => {
                                                return (
                                                    <li key={text2.key}>{String.fromCharCode(97 + index)}) {text2.description}</li>
                                                );
                                            })}
                                        </ol>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >

            <Bottom />
            <Footer1 />
        </>
    )
}