import React from "react";
import { Link, useHistory } from "react-router-dom";
// import PageTitle from '../element/page-title';
import Footer2 from "../layout/footer2";
// import { Row, Col, Card } from 'react-bootstrap';
import Header2 from "../layout/header2";
import Sidebar from "../layout/sidebar";
import { useContext } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { useRef } from "react";
import { useState } from "react";
import { Button } from "react-bootstrap";
import { isEmpty } from "lodash";
import { BACKEND_API_URL, HTTP_SUCCESS_STATUS, MAX_IMAGE_SIZE, USER_STATUS } from "../../shared/constants";
import { toast } from "react-hot-toast";
import { useMyInfo } from "../../state/hooks";
import moment from "moment";
import Axios from "axios"

function VerifyStep2() {

    const history = useHistory()

    const { currentUser, authToken, isAuthenticated } = useContext(AuthContext)

    const { data, refetch: userInfoRefetch, isRefetching: userInfoRefetching } = useMyInfo(authToken, currentUser?.id)

    const [isSubmitting, setIsSubmitting] = useState(false)

    const idFrontRef = useRef()
    const idBackRef = useRef()

    const handleSubmit = async () => {

        if (!isAuthenticated || isEmpty(currentUser) || !authToken || currentUser.status == USER_STATUS.VERIFYING || currentUser.status == USER_STATUS.VERIFIED) {
            toast.error("Please login or your account is verified already.")
            return false
        }

        if (!data?.user?.password_last_verified_at || moment(data?.user?.password_last_verified_at).valueOf() < moment().subtract(1, 'days').valueOf()) {
            history.push('/confirm-password?from=settings')
            return false
        }

        // check if password is valid or not

        const idFrontImage = idFrontRef.current.files[0]
        const idBackImage = idBackRef.current.files[0]

        if (!idFrontImage || !idBackImage) {
            toast.error("Please select ID card front image and back image.")
            return false
        }

        if (idFrontImage.size > MAX_IMAGE_SIZE || idBackImage.size > MAX_IMAGE_SIZE) {
            toast.error("File size exceeded 1MB.")
            return false
        }

        const submittedData1 = new FormData()
        submittedData1.append('file', idFrontImage)

        const submittedData2 = new FormData()
        submittedData2.append('file', idBackImage)

        const _toast = toast.loading("Uploading images...")
        setIsSubmitting(true)

        try {

            // send listing request
            const uploadResult1 = await Axios.post(`${BACKEND_API_URL}/user/send-id/front`, submittedData1, { headers: { Authorization: `Bearer ${authToken}` } })
            const uploadResult2 = await Axios.post(`${BACKEND_API_URL}/user/send-id/back`, submittedData2, { headers: { Authorization: `Bearer ${authToken}` } })

            if (uploadResult1.status != HTTP_SUCCESS_STATUS || uploadResult2.status != HTTP_SUCCESS_STATUS) {
                toast.dismiss(_toast)
                toast.error(uploadResult1?.data?.message)
                setIsSubmitting(false)
                return false
            }

            toast.dismiss(_toast)
            toast.success("ID card successfully uploaded!")
            history.push('/settings-security')
        }

        catch (e) {
            console.log("send-id-card", e)
            toast.dismiss(_toast)
            if (e?.response?.data?.message) {
                console.log("ID-Verify-Page@send-id-error:", e?.response?.data?.message)
                toast.error(e?.response?.data?.message)
            } else {
                console.error('ID-Verify-Page@send-id-error:', e.message)
                toast.error(e.message)
            }
        }
        setIsSubmitting(false)
        return false

    }

    return (
        <>
            <Header2 />

            <div className="verification section-padding">
                <div className="container h-100">
                    <div className="row justify-content-center h-100 align-items-center">
                        <div className="col-xl-5 col-md-6">
                            <div className="auth-form card">
                                <div className="card-body">
                                    <form className="identity-upload">
                                        <div className="identity-content">
                                            <h4>Upload your ID card</h4>
                                            <span>
                                                (Driving License or Government
                                                ID card)
                                            </span>

                                            <p>
                                                Uploading your ID helps as
                                                ensure the safety and security
                                                of your founds
                                            </p>
                                        </div>

                                        <div className="mb-3">
                                            <label className="form-label">
                                                Upload Front ID{" "}
                                            </label>
                                            <span className="float-right">
                                                Maximum file size is 1MB.
                                            </span>
                                            <div
                                                className="file-upload-wrapper"
                                                data-text="front.jpg"
                                            >
                                                <input
                                                    name="file-upload-field"
                                                    type="file"
                                                    className="file-upload-field"
                                                    ref={idFrontRef}
                                                />
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label">
                                                Upload Back ID{" "}
                                            </label>
                                            <span className="float-right">
                                                Maximum file size is 500kb
                                            </span>
                                            <div
                                                className="file-upload-wrapper"
                                                data-text="back.jpg"
                                            >
                                                <input
                                                    name="file-upload-field"
                                                    type="file"
                                                    className="file-upload-field"
                                                    ref={idBackRef}
                                                />
                                            </div>
                                        </div>

                                        <div className="text-center">
                                            {!isSubmitting ? <Button
                                                className="btn btn-success ps-5 pe-5"
                                                onClick={handleSubmit}
                                            >
                                                Submit
                                            </Button> : <i className="fa fa-spinner text-primary fa-spin fa-3x fa-fw"></i>}
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer2 />
        </>
    );
}

export default VerifyStep2;
