import React from 'react';
import Footer2 from '../layout/footer2';
// import { Link } from 'react-router-dom';
// import { Row, Col, Card } from 'react-bootstrap';
import Header2 from '../layout/header2';
import Sidebar from '../layout/sidebar';
import Axios from 'axios';
import { useHistory, useParams } from 'react-router-dom';
import { BACKEND_API_URL, HTTP_SUCCESS_CODE, HTTP_SUCCESS_STATUS } from '../../shared/constants';
import { useQuery } from 'react-query';
import { LinearProgress } from '@mui/material';
import { toast } from 'react-hot-toast';

function ResetPasswordVerify() {
  const { id, token } = useParams();

  const history = useHistory();

  const fetchData = async ({ queryKey }) => {
    const [_, _id, _token] = queryKey;

    try {
      // if have token to verify
      const response = await Axios.post(`${BACKEND_API_URL}/user/verify-reset-password`, { id: _id, token: _token });

      if (response?.status === HTTP_SUCCESS_STATUS && response?.data?.status === HTTP_SUCCESS_CODE) {
        history.push('/signin');
      }
    } catch (e) {
      if (e?.response?.data?.message) {
        console.log('Deposit-Page@deposit-error:', e?.response?.data?.message);
        toast.error(e?.response?.data?.message);
      } else {
        console.error('Deposit-Page@deposit-error:', e.message);
        toast.error(e.message);
      }
    }

    return false;
  };

  const { isLoading } = useQuery(['verify-reset-password', id, token], fetchData, {
    refetchOnWindowFocus: false,
    //enabled: false, // disable this query from automaticaslly running
    // staleTime: token === undefined ? 4000 : Infinity,
    // refetchInterval: token === undefined ? 4000 : false,
    // retry: true
  });

  return (
    <>
      <Header2 />
      {isLoading && <LinearProgress color="info" />}
      <div className="verification section-padding">
        <div className="container h-100">
          <div className="row justify-content-center h-100 align-items-center">
            <div className="col-xl-5 col-md-6">
              <div className="auth-form card">
                <div className="card-body">
                  <form
                    action="verify-step-4.html"
                    className="identity-upload"
                  >
                    <div className="identity-content">
                      <span className="icon">
                        <i className="fa fa-shield"></i>
                      </span>
                      <h4>Verifying Reset Password Request...</h4>
                      <p>We have sent message to your mailbox.</p>
                    </div>

                    <div className="upload-loading text-center mb-3">
                      <i className="fa fa-spinner fa-spin fa-3x fa-fw"></i>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer2 />
    </>
  );
}

export default ResetPasswordVerify;
