import React from 'react';
import { Accordion, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import AreaChart from '../charts/area';
import PageTitle from '../element/page-title';
import Popup from '../element/popup';
import Footer2 from '../layout/footer2';
// import { Row, Col, Card } from 'react-bootstrap';
import Header2 from '../layout/header2';
import Sidebar from '../layout/sidebar';



function Dashboard() {

    return (
        <>
            <Header2 />
            <Sidebar activePage={`dashboard`} />
            <PageTitle />

            <div className="content-body">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12">
                        </div>

                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-header bg-info">
                                    <h4 className="card-title">Today's Exchange </h4>
                                </div>
                                <div className="card-body">
                                    <div className='row'>
                                        <div className='col-sm-12 col-md-12 col-lg-6'>
                                            <div className='col-md-12 h3 mb-3'>
                                                <i className="cc BTC"></i><i className="cc LTC overlap"></i>CAX/DAI (Polygon)
                                                <span className='float-end text-primary'>+2.5%</span>
                                            </div>
                                            <div id="timeline-chart">
                                                <AreaChart />
                                            </div>
                                        </div>
                                        <div className='col-sm-12 col-md-12 col-lg-6'>
                                            <div className='col-md-12 h3 mb-3 '>
                                                <i className="cc BTC"></i><i className="cc LTC overlap"></i>Kamhol/DAI (Polygon)
                                                <span className='float-end text-danger'>-2.5%</span>
                                            </div>
                                            <div id="timeline-chart">
                                                <AreaChart />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title">All Exchanges</h4>
                                </div>
                                <div className="card-body">
                                    <div className="transaction-table">
                                        <div className="table-responsive">
                                            <table className="table table-striped mb-0 table-responsive-sm">
                                                <tbody>
                                                    <tr>
                                                        <td className="text-white-50"><span className="sold-thumb"><i className="mdi mdi-arrow-down"></i></span>
                                                        </td>

                                                        <td className="text-white-50">
                                                            <span className="badge badge-danger p-2">Sold</span>
                                                        </td>
                                                        <td className="text-white-50">
                                                            <i className="cc BTC"></i> BTC
                                                        </td>
                                                        <td className="text-white-50">
                                                            Using - Bank *******5264
                                                        </td>
                                                        <td className="text-danger">-0.000242 BTC</td>
                                                        <td className="text-white-50">-0.125 USD</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-white-50"><span className="buy-thumb"><i className="mdi mdi-arrow-up"></i></span>
                                                        </td>
                                                        <td className="text-white-50">
                                                            <span className="badge badge-success p-2">Buy</span>
                                                        </td>
                                                        <td className="text-white-50">
                                                            <i className="cc LTC"></i> LTC
                                                        </td>
                                                        <td className="text-white-50">
                                                            Using - Card *******8475
                                                        </td>
                                                        <td className="text-success">-0.000242 BTC</td>
                                                        <td className="text-white-50">-0.125 USD</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-white-50"><span className="sold-thumb"><i className="mdi mdi-arrow-down"></i></span>
                                                        </td>
                                                        <td className="text-white-50">
                                                            <span className="badge badge-danger p-2">Sold</span>
                                                        </td>
                                                        <td className="text-white-50">
                                                            <i className="cc XRP"></i> XRP
                                                        </td>
                                                        <td className="text-white-50">
                                                            Using - Card *******8475
                                                        </td>
                                                        <td className="text-danger">-0.000242 BTC</td>
                                                        <td className="text-white-50">-0.125 USD</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-white-50"><span className="buy-thumb"><i className="mdi mdi-arrow-up"></i></span>
                                                        </td>
                                                        <td className="text-white-50">
                                                            <span className="badge badge-success p-2">Buy</span>
                                                        </td>
                                                        <td className="text-white-50">
                                                            <i className="cc DASH"></i> DASH
                                                        </td>
                                                        <td className="text-white-50">
                                                            Using - Card *******2321
                                                        </td>
                                                        <td className="text-success">-0.000242 BTC</td>
                                                        <td className="text-white-50">-0.125 USD</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-white-50"><span className="sold-thumb"><i className="mdi mdi-arrow-down"></i></span>
                                                        </td>
                                                        <td className="text-white-50">
                                                            <span className="badge badge-danger p-2">Sold</span>
                                                        </td>
                                                        <td className="text-white-50">
                                                            <i className="cc BTC"></i> BTC
                                                        </td>
                                                        <td className="text-white-50">
                                                            Using - Card *******2321
                                                        </td>
                                                        <td className="text-danger">-0.000242 BTC</td>
                                                        <td className="text-white-50">-0.125 USD</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title">FAQ</h4>
                                </div>
                                <div className="card-body">
                                    <Accordion defaultActiveKey="0" id="accordion-faq" className="accordion">
                                        <Card>
                                            <Accordion.Toggle as={Card.Header} eventKey="0">
                                                <h5>What Shipping Methods are Available?</h5>
                                            </Accordion.Toggle>
                                            <Accordion.Collapse eventKey="0">
                                                <Card.Body>Anim pariatur cliche reprehenderit, enim eiusmod high
                                                    life accusamus terry richardson ad squid. 3 wolf moon officia aute, non
                                                    cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum
                                                    eiusmod.</Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                        <Card>
                                            <Accordion.Toggle as={Card.Header} eventKey="1">
                                                <h5>How Long Will it Take To Get My Package?</h5>
                                            </Accordion.Toggle>
                                            <Accordion.Collapse eventKey="1">
                                                <Card.Body>Anim pariatur cliche reprehenderit, enim eiusmod high
                                                    life accusamus terry richardson ad squid. 3 wolf moon officia aute, non
                                                    cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum
                                                    eiusmod.</Card.Body>
                                            </Accordion.Collapse>
                                        </Card>

                                        <Card>
                                            <Accordion.Toggle as={Card.Header} eventKey="2">
                                                <h5>How Do I Track My Order?</h5>
                                            </Accordion.Toggle>
                                            <Accordion.Collapse eventKey="2">
                                                <Card.Body>Anim pariatur cliche reprehenderit, enim eiusmod high
                                                    life accusamus terry richardson ad squid. 3 wolf moon officia aute, non
                                                    cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum
                                                    eiusmod.</Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                        <Card>
                                            <Accordion.Toggle as={Card.Header} eventKey="3">
                                                <h5>How Do I Track My Order?</h5>
                                            </Accordion.Toggle>
                                            <Accordion.Collapse eventKey="3">
                                                <Card.Body>Anim pariatur cliche reprehenderit, enim eiusmod high
                                                    life accusamus terry richardson ad squid. 3 wolf moon officia aute, non
                                                    cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum
                                                    eiusmod.</Card.Body>
                                            </Accordion.Collapse>
                                        </Card>

                                    </Accordion>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12">
                            <Popup />
                        </div>
                    </div>
                </div>
            </div>

            <Footer2 />
        </>
    )
}

export default Dashboard;