import React, { useContext, useEffect, useState } from 'react';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext';
import { debounce, isEmpty } from 'lodash';
import TokenInfoSVG from '../../images/token-info.svg'
import OnRampSVG from '../../images/on-ramp.svg'

const home = (
    <Tooltip id="home">
        HOME
    </Tooltip>
);
const onRamp = (
    <Tooltip id="on-ramp">
        ON RAMP
    </Tooltip>
);
const exchange = (
    <Tooltip id="exchange">
        EXCHANGE
    </Tooltip>
);
const accounts = (
    <Tooltip id="accounts">
        BALANCES
    </Tooltip>
);
const farm = (
    <Tooltip id="farm">
        FARMING
    </Tooltip>
);
const tokenInfo = (
    <Tooltip id="listed tokens">
        TOKEN INFO & LISTING
    </Tooltip>
);
const settings = (
    <Tooltip id="settings">
        PROFILE
    </Tooltip>
);

const login = (
    <Tooltip id="sign_in">
        SIGN IN
    </Tooltip>
);

function Sidebar({ activePage }) {

    const { isAuthenticated, currentUser } = useContext(AuthContext)

    return (
        <>
            <div className={`sidebar`}>
                <div className="menu">
                    <ul>
                        {/* <li>
                            <Link to={"/dashboard"} activeclassname="active" className={activePage === "dashboard" && `active`}>
                                <OverlayTrigger placement="right" overlay={home}>
                                    <span><i className="mdi mdi-view-dashboard"></i></span>
                                </OverlayTrigger>
                            </Link>
                        </li> */}
                        <li>
                            <Link to={"/exchange/ethereum/cax_dai"} activeclassname="active" className={activePage === "exchange" ? `active` : ``}>
                                <OverlayTrigger placement="right" overlay={exchange}>
                                    <span><i className="mdi mdi-tumblr-reblog"></i></span>
                                </OverlayTrigger>
                            </Link>
                        </li>
                        <li>
                            <Link to={"/on-ramp"} activeclassname="active" className={activePage === "onRamp" ? `active` : ``}>
                                <OverlayTrigger placement="right" overlay={onRamp}>
                                    <span><img src={OnRampSVG} width={`20px`} style={{ filter: "invert(1)" }} /></span>
                                </OverlayTrigger>
                            </Link>
                        </li>
                        <li>
                            <Link to={"/farm"} activeclassname="active" className={activePage === "farm" ? `active` : ``}>
                                <OverlayTrigger placement="right" overlay={farm}>
                                    <span><i className="mdi mdi-tractor"></i></span>
                                </OverlayTrigger>
                            </Link>
                        </li>
                        <li>
                            <Link to={"/token-info"} activeclassname="active" className={activePage === "token-info" ? `active` : ``}>
                                <OverlayTrigger placement="right" overlay={tokenInfo}>
                                    <span><img src={TokenInfoSVG} width={`20px`} /></span>
                                </OverlayTrigger>
                            </Link>
                        </li>
                        {isAuthenticated && !isEmpty(currentUser) && <><li>
                            <Link to={"/account-overview"} activeclassname="active" className={activePage === "account-overview" ? `active` : ``}>
                                <OverlayTrigger placement="right" overlay={accounts}>
                                    <span><i className="mdi mdi-face-profile"></i></span>
                                </OverlayTrigger>
                            </Link>
                        </li>
                            <li>
                                <Link to={"/settings"} activeclassname="active" className={activePage === "settings" ? `active` : ``}>
                                    <OverlayTrigger placement="right" overlay={settings}>
                                        <span><i className="mdi mdi-settings"></i></span>
                                    </OverlayTrigger>
                                </Link>
                            </li>
                        </>}
                        {!isAuthenticated && <li>
                            <Link to={"/signin"} activeclassname="active" className={activePage === "signin" ? `active` : ``}>
                                <OverlayTrigger placement="right" overlay={login}>
                                    <span><i className="mdi mdi-login"></i></span>
                                </OverlayTrigger>
                            </Link>
                        </li>}
                    </ul>
                </div>
            </div>
        </>
    )
}

export default Sidebar;