import React from 'react';
import { useContext } from 'react';
import { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext';
import { Button } from 'react-bootstrap';
import { BACKEND_API_URL, HTTP_SUCCESS_STATUS, USER_STATUS } from '../../shared/constants';
import { isEmpty } from 'lodash';
import { toast } from 'react-hot-toast';
import Axios from 'axios';

function ConfirmPassword() {
  const queryParams = new URLSearchParams(window.location.search);
  const from = queryParams.get('from');

  const history = useHistory();

  const { currentUser, authToken, isAuthenticated } = useContext(AuthContext);

  const [password, setPassword] = useState('');
  const [isConfirming, setConfirming] = useState(false);

  const handleConfirm = async () => {
    if (
      !isAuthenticated ||
      isEmpty(currentUser) ||
      !authToken
    ) {
      toast.error('Please login to confirm password.');
      return false;
    }

    if (!password || password.length < 8) {
      toast.error('Password should be longer than 8 characters.');
      return false;
    }

    const _toast = toast.loading('Confirming password...');
    setConfirming(true);

    try {
      // send listing request
      const changeResult = await Axios.post(
        `${BACKEND_API_URL}/user/confirm-password`,
        { password },
        { headers: { Authorization: `Bearer ${authToken}` } }
      );

      if (changeResult.status != HTTP_SUCCESS_STATUS) {
        toast.dismiss(_toast);
        toast.error('Unexpected Error Occured!');
        setConfirming(false);
        return false;
      }

      toast.dismiss(_toast);
      toast.success('Password Successfully Confirm!');

      history.push(`/${from}`);
    } catch (e) {
      console.log('cofirm-password', e);
      toast.dismiss(_toast);
      toast.error('Unexpected Error Occured!');
    }
    setConfirming(false);
    return false;
  };

  return (
    <>
      <div className="authincation section-padding">
        <div className="container h-100">
          <div className="row justify-content-center h-100 align-items-center">
            <div className="col-xl-5 col-md-6">
              <div className="mini-logo text-center my-5">
                <Link to={'./'}>
                  <img
                    src={require('../../images/logo.png')}
                    alt=""
                    width={`300px`}
                  />
                </Link>
              </div>
              <div className="auth-form card">
                <div className="card-header justify-content-center">
                  <h4 className="card-title">Confirm password</h4>
                </div>
                <div className="card-body">
                  <div className="mb-3">
                    <label>Password</label>
                    <input
                      type="password"
                      className="form-control"
                      value={password}
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                    />
                  </div>
                  <div className="text-center">
                    <Button
                      className="btn btn-success btn-block"
                      onClick={handleConfirm}
                      disabled={isConfirming}
                    >
                      Confirm
                    </Button>
                  </div>
                  {/* <div className="new-account mt-3">
                                        <p className="mb-1">Don't Received? </p>
                                        <Link className="text-primary" to={"./reset"}>Resend </Link>
                                    </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ConfirmPassword;
