import React, { useState } from "react";
import { Link } from "react-router-dom";
import Footer2 from "../layout/footer2";
import Footer1 from "../layout/footer1";
import Header2 from "../layout/header2";
import Sidebar from "../layout/sidebar";
import Bottom from "../element/bottom";

export const EsignConsent = () => {

    return (
        <>
            <Header2 />
            <div className="content-body" style={{ marginTop: '45px' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-header fw-bold">
                                    <h3>
                                        E-Sign Consent
                                    </h3>
                                </div>
                                <div className="card-body">
                                    <div className="legal-doc">
                                        <span>
                                        If you open an account on the Caladex platform you automatically agree and 
                                        give your consent to receiving electronic communications, which will include, all 
                                        notifications, statements, disclosures, agreements, fee tables, explanation of our 
                                        services, confirmed transactions, order history, inquiries, and other communications
                                        that Caladex provides to you. All electronic communications are equal to traditional 
                                        communications in writing and will have the same effect as if the communications 
                                        were written and signed by Caladex. You agree that Caladex may give any 
                                        notification allowed or needed under this agreement by means of email, posting on 
                                        its website or mobile app. Any email correspondences will be conveyed to the email 
                                        address registered to your account. You should notify us of any change in your email
                                        address or cell phone number. This can be done by signing into your account and 
                                        following the directions there. Your consent applies to all communications from 
                                        Caladex during the period that you hold an account with us. You are free to revoke 
                                        your consent to receive electronic communications by sending us an email request 
                                        to info@Caladex.com. In the case you withdraw your consent you will have to 
                                        transfer your Digital Assets within 48 hours to either a linked bank account or 
                                        other third-party cryptocurrency addresses. If you fail to do so within the designated
                                        time period, we will liquidate your account balance on your behalf, convert the 
                                        total amount to U.S. Dollars or EUROS and transfer it to a linked bank account. 
                                        We do not issue or provide paper versions of our electronic documents. In order to 
                                        communicate with Caladex in order to send or receive electronic documents you 
                                        must have a mobile device (iPhone or Android) or a computer with an internet 
                                        connection, compatible internet browser (such as Chrome, Safari, Opera or 
                                        Microsoft Edge), and software that is capable of reading and displaying portable 
                                        document format files (PDF). In addition, you will need access to your email 
                                        address which is registered with your account and an e-mail application that can 
                                        display text messages. Any documents or messages we sent to you are deemed 
                                        delivered to your email address on file. You agree that we are not responsible for 
                                        any electronic communications you did not receive due to outdated email, or 
                                        your inability to receive electronic communications.
                                        <br/>
                                        <br/>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >

            <Bottom />
            <Footer1 />
        </>
    )
}