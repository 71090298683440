import React, { useContext, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext';
import Axios from 'axios';
import { BACKEND_API_URL, HTTP_SUCCESS_STATUS } from '../../shared/constants';
import { escapeHtmlObject, setRefreshToken } from '../../shared/helpers';
import { toast } from 'react-hot-toast';
import * as Yup from 'yup'

function Otp2() {
  const [otpCode, setOtpCode] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setLoading] = useState(false);

  const history = useHistory();

  const { currentUser, setAuthProcessing, setAuthenticated, setCurrentUser, setAuthToken } = useContext(AuthContext);

  const handleSubmit = async () => {
    setLoading(true);
    if (otpCode.length !== 6) {
      setError('Invalid OTP code detected! Please try again.');
      setLoading(false);
      return false;
    }

    // //fake
    // history.push('dashboard')
    // return false
    // //endfake

    try {
      if (!currentUser?.id) {
        setAuthProcessing(0);
        setAuthenticated(false);
        history.push('signin');
      }

      let response = await Axios.post(`${BACKEND_API_URL}/auth/verify-otp`, {
        ...escapeHtmlObject({ id: currentUser.id, otp: otpCode }),
      });
      if (response?.status !== HTTP_SUCCESS_STATUS) {
        throw new Error(response?.data.message);
      }
      setAuthProcessing(0);

      const { user, token, refreshToken } = response?.data?.data;
      setCurrentUser({ ...user });
      setAuthToken(token);
      setRefreshToken(refreshToken);
      setAuthenticated(true);
      history.push('/exchange/ethereum/cax_dai');
    } catch (e) {
      if (e?.response?.data?.message) {
        console.log('Otp-2-Page@verify-otp-error:', e?.response?.data?.message);
        setError(e?.response?.data?.message);
      } else {
        console.error('Otp-2-Page@verify-otp-error:', e.message);
        setError(e.message);
      }
    }
    setLoading(false);
    return false;
  };

  const handleChangePhoneNumber = async (e) => {
    e.preventDefault()

    if (!currentUser?.id || currentUser?.phone_verified_at) {
      toast.error("Please login to your account.")
      setAuthProcessing(0);
      setAuthenticated(false);
      history.push('signin');
      return false
    }
    const payloadSchema = Yup.object().shape({
      id: Yup.string().min(10).max(255).required(),
    })

    let validationResult = ""

    await payloadSchema.validate({ id: currentUser?.id })
      .catch((err) => {
        validationResult = err.message;
        return false
      });

    if (validationResult) {
      console.log(validationResult)
      toast.error("Invalid parameter detected.")
      return false
    }

    const _toast = toast.loading("Changing Phone Number...")
    try {
      let response = await Axios.post(`${BACKEND_API_URL}/user/change-phone`, { id: currentUser?.id })

      if (response?.status !== HTTP_SUCCESS_STATUS) {
        toast.error(response?.data?.message)
      } else {
        toast.error('Phone number successfully removed.')
        history.push('otp-1');
      }
    }

    catch (e) {
      if (e?.response?.data?.message) {
        console.log('Otp-2-Page@change-phone-number:', e?.response?.data?.message);
        toast.error(e?.response?.data?.message);
      } else {
        toast.error(e.message);
        console.log('Otp-2-Page@change-phone-number:', e?.message);
      }
    }

    finally {
      toast.dismiss(_toast)
    }

    return false

  }

  return (
    <>
      <div className="authincation section-padding">
        <div className="container h-100">
          <div className="row justify-content-center h-100 align-items-center">
            <div className="col-xl-5 col-md-6">
              <div className="mini-logo text-center my-5">
                <Link to={'./'}>
                  <img
                    src={require('../../images/logo.png')}
                    alt=""
                    width="300px"
                    style={{}}
                  />
                </Link>
              </div>
              <div className="auth-form card">
                <div className="card-body">
                  <Link
                    className="page-back text-muted"
                    to={'/otp-1'}
                  >
                    <span>
                      <i className="fa fa-angle-left"></i>
                    </span>{' '}
                    Back
                  </Link>
                  <h3 className="text-center">OTP Verification</h3>
                  <p className="text-center mb-5">We will send one time code on this number</p>
                  <form>
                    <div className="mb-3">
                      <label>Your OTP Code</label>
                      <input
                        type="text"
                        className="form-control text-center font-weight-bold"
                        placeholder="11 22 33"
                        value={otpCode}
                        onChange={(e) => {
                          setOtpCode(e.target.value);
                        }}
                      />
                    </div>
                    {error && <p className="text-danger my-3 text-center">{error}</p>}
                    <div className="text-center">
                      {!isLoading ? (
                        <Link
                          to={'#'}
                          className="btn btn-success btn-block"
                          onClick={() => {
                            handleSubmit();
                          }}
                        >
                          Verify
                        </Link>
                      ) : (
                        <i className="fa fa-spinner text-primary fa-spin fa-2x fa-fw"></i>
                      )}
                    </div>
                  </form>
                  <div className="info mt-3">
                    <p className="text-muted">You dont recommended to save password to browsers!</p>
                  </div>
                  <div className='d-flex justify-content-end'>
                    <Link to={`#`} className={`text-primary`} onClick={handleChangePhoneNumber}>Change Phone Number</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Otp2;
