import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  atomFarmingPoolList,
  atomTargetTokenList,
  atomTokenOrderList,
  atomTokenPairBalance,
  atomTokenPairList,
  atomTokenPriceList,
  atomTradingViewData,
  atomWalletCaladexBalance,
  atomWalletTokenBalance,
} from './atoms';
import { useQuery } from 'react-query';
import {
  BACKEND_API_URL,
  CHAIN_LIST,
  EXCHANGE_TYPE,
  FARMING_ADDRESSES,
  TOKEN_POLLING_INTERVAL,
} from '../shared/constants';
import Axios from 'axios';
import Web3 from 'web3';
import { getChainPoolList, getTokenBalance } from '../shared/web3';
import { getTokenPrice, fetchGeckoPrice } from '../shared/ExchangeService';
import { isEmpty, orderBy } from 'lodash';
import { atomTokenTradeList } from './atoms';
import moment from 'moment';
import { useAccount } from 'wagmi';

/**
 *
 * @returns get token pair list
 */

export const useTokenPairList = () => {

  const settokenPairList = useSetRecoilState(atomTokenPairList);

  const fetchData = async () => {
    const response = await Axios.post(`${BACKEND_API_URL}/tokeninfo/getall`, {});

    const { data } = response;

    // const _compatibleData = await Promise.all(
    // data.data.map(async (token) => {
    //   console.log('********', token);
    const tokenPrice = await fetchGeckoPrice()
    const _compatibleData = data.data.map((token) => { 
      const coingeckoPrice = tokenPrice? tokenPrice.find(element=>element.symbol===token.token_id.symbol.toLowerCase()):undefined
      if(coingeckoPrice) token.price = coingeckoPrice.current_price
      // const _usd_token_price = await getTokenPrice(token.token_id.symbol);
      
      return {
        ...token.token_id,
        ...token,
        token_id: token.token_id._id,
        high24hr: token.day_high,
        low24hr: token.day_low,
        volume24hr: token.volume,
        percentChange: token.day_change,
        total24hr: 0,
        last: token.last_price,
        // price: _usd_token_price,
      };
    })
    // );
    const compatibleData = orderBy(_compatibleData, ['percentChange', 'volume24hr'], ['desc', 'desc'])
    settokenPairList(compatibleData.filter((_token) => CHAIN_LIST.find((chain) => chain.chainId === _token.chain_id)));
  };

  return useQuery('token-pair-list', fetchData, {
    refetchOnWindowFocus: false,
    enabled: true,
    staleTime: Infinity,
    retryDelay: 4000,
    refetchInterval: 20000,
    retry: true,
  });
};

/**
 *
 * @returns token balance for account
 */
export const useTargetTokenList = () => {
  const setTargetTokenList = useSetRecoilState(atomTargetTokenList);

  const fetchData = async () => {
    const response = await Axios.post(`${BACKEND_API_URL}/token/get/target/all`, {});

    const { data } = response;

    setTargetTokenList(data.data.filter((_token) => CHAIN_LIST.find((chain) => chain.chainId === _token.chain_id)));

    return false;
  };

  return useQuery('target-token-list', fetchData, {
    refetchOnWindowFocus: false,
    enabled: true,
    staleTime: Infinity,
    retryDelay: 4000,
    // refetchInterval: TOKEN_POLLING_INTERVAL,
    retry: true,
  });
};

/**
 *
 * @returns all orders for token pair
 */

export const useTokenOrderList = (chain_id, token_symbol, pair_token) => {
  const tokenPairList = useRecoilValue(atomTokenPairList);
  const setTokenOrderList = useSetRecoilState(atomTokenOrderList);

  const fetchData = async () => {
    if (isEmpty(tokenPairList)) {
      return false;
    }

    const token = tokenPairList.find(
      (token) => token.symbol === token_symbol?.toUpperCase() && token.chain_id === chain_id
    );

    const response = await Axios.post(`${BACKEND_API_URL}/order/get`, {
      chain_id,
      token_id: token?.token_id,
      pair_token: pair_token?.toUpperCase(),
    });

    const { data } = response;

    const buyOrders = data?.data?.data
      ?.filter((_order) => _order.type === EXCHANGE_TYPE.BUY && !_order.is_traded)
      .sort((a, b) => +b.price - +a.price);
    const sellOrders = data?.data?.data
      ?.filter((_order) => _order.type === EXCHANGE_TYPE.SELL && !_order.is_traded)
      .sort((a, b) => +b.price - +a.price);
    const allOrders = data?.data?.data?.sort((a, b) => moment(b.time).valueOf() - moment(a.time).valueOf());

    setTokenOrderList([getSamePriceMergedOrderList(buyOrders), getSamePriceMergedOrderList(sellOrders), allOrders, data?.tokenInfo]);
  };

  return useQuery('token-order-list', fetchData, {
    refetchOnWindowFocus: false,
    enabled: true,
    staleTime: Infinity,
    retryDelay: 4000,
    refetchInterval: TOKEN_POLLING_INTERVAL,
    retry: true,
  });
};

/**
 *
 * @returns all trades for token pair
 */

export const useTokenTradeList = (chain_id, token_symbol, pair_token) => {
  const tokenPairList = useRecoilValue(atomTokenPairList);
  const setTokenTradeList = useSetRecoilState(atomTokenTradeList);

  const fetchData = async () => {
    if (isEmpty(tokenPairList)) {
      return false;
    }

    const token = tokenPairList.find(
      (token) => token.symbol === token_symbol?.toUpperCase() && token.chain_id === chain_id
    );

    const response = await Axios.post(`${BACKEND_API_URL}/trade/get`, {
      token_id: token?.token_id,
      pair_token: pair_token?.toUpperCase(),
    });

    const { data } = response;

    setTokenTradeList(data?.data?.data);

    return false;
  };

  return useQuery('token-trade-list', fetchData, {
    refetchOnWindowFocus: false,
    enabled: true,
    staleTime: Infinity,
    retryDelay: 4000,
    refetchInterval: TOKEN_POLLING_INTERVAL,
    retry: true,
  });
};

export const useTradingViewData = (chain_id, token_symbol, pair_token, enabled = true) => {
  const tokenPairList = useRecoilValue(atomTokenPairList);
  const setTradingViewData = useSetRecoilState(atomTradingViewData);

  const fetchData = async () => {
    if (isEmpty(tokenPairList)) {
      return false;
    }

    const token = tokenPairList.find(
      (token) => token.symbol === token_symbol?.toUpperCase() && token.chain_id === chain_id
    );

    const response = await Axios.post(`${BACKEND_API_URL}/tradingview/get`, {
      token_id: token?.token_id,
      pair_token: pair_token?.toUpperCase(),
    });

    const { data } = response;

    setTradingViewData(data?.data?.data?.information ?? []);
  };

  return useQuery('trading-view-list', fetchData, {
    refetchOnWindowFocus: false,
    enabled: enabled,
    staleTime: Infinity,
    retryDelay: 4000,
    refetchInterval: enabled ? TOKEN_POLLING_INTERVAL : 0,
    retry: true,
  });
};

export const useWalletTokenBalance = () => {
  const setWalletTokenBalance = useSetRecoilState(atomWalletTokenBalance);

  const tokenPairlist = useRecoilValue(atomTokenPairList);
  const targetTokenList = useRecoilValue(atomTargetTokenList);

  const { address, isConnected } = useAccount();

  const fetchData = async () => {
    if (isEmpty(tokenPairlist) || isEmpty(targetTokenList)) {
      return false;
    }

    // let usdBalance = 0;
    const utillityTokenList = [];
    tokenPairlist.map((value, index, array) => {
      if (utillityTokenList.find(token => token.token_id === value.token_id)) {
        return false
      }
      utillityTokenList.push(value);
      return true;
    })

    try {
      const walletBalances = await Promise.all(
        [...utillityTokenList, ...targetTokenList].map(async (token) => {
          const chain = CHAIN_LIST.find((_chain) => _chain.chainId === token.chain_id);
          const web3 = new Web3(chain?.rpcUrls[0]);
          try {
            const _tokenBalance = await getTokenBalance(address, token.address, web3, token.decimal);

            // const _usdPrice = await getTokenPrice(token.symbol);
            // usdBalance += _tokenBalance * _usdPrice;
            
            return { token_id: token.token_id ?? token._id, balance: _tokenBalance };
          }
          catch (e) {
            return { token_id: token.token_id ?? token._id, balance: 0 };
          }
        })
      );
      setWalletTokenBalance(walletBalances);
    } catch (e) {
      console.error('wallet-token-balance:', e.message);
    }
    return false;
  };

  return useQuery('wallet-token-balances', fetchData, {
    refetchOnWindowFocus: false,
    enabled: true,
    staleTime: Infinity,
    retryDelay: 4000,
    // refetchInterval: TOKEN_POLLING_INTERVAL,
    retry: true,
  });
};

export const useFarmingPoolList = () => {
  const setFarmingPoolList = useSetRecoilState(atomFarmingPoolList);

  const fetchData = async () => {
    // if (!active || isEmpty(tokenPairlist)) {
    //     return false
    // }

    const poolList = await Promise.all(
      CHAIN_LIST.map(async (chain) => {
        const web3 = new Web3(chain?.rpcUrls[0]);
        const farmingAddress =
          FARMING_ADDRESSES[chain.chainId] === undefined ? FARMING_ADDRESSES['0x1'] : FARMING_ADDRESSES[chain.chainId];
        const _poolList = await getChainPoolList(farmingAddress, web3);
        return { chainId: chain.chainId, pools: _poolList };
      })
    );

    setFarmingPoolList(poolList);

    return false;
  };

  return useQuery('farming-pool-list', fetchData, {
    refetchOnWindowFocus: false,
    enabled: true,
    staleTime: Infinity,
    retryDelay: 4000,
    // refetchInterval: TOKEN_POLLING_INTERVAL,
    retry: true,
  });
};

export const useTokenPriceList = () => {
  const setTokenPriceList = useSetRecoilState(atomTokenPriceList);

  const tokenPairlist = useRecoilValue(atomTokenPairList);
  const targetTokenList = useRecoilValue(atomTargetTokenList);

  const fetchData = async () => {
    if (isEmpty(tokenPairlist) || isEmpty(targetTokenList)) {
      return false;
    }

    const targetPrices = await Promise.all(
      targetTokenList.map(async (token) => {
        const _usdPrice = await getTokenPrice(token.symbol);
        return { token_id: token._id, symbol: token.symbol, usdPrice: _usdPrice };
      })
    );

    const tokenPrices = await Promise.all(
      tokenPairlist.map(async (token) => {
        const _usd_token_price = await getTokenPrice(token.symbol);
        console.log(token.symbol, _usd_token_price);
        const pairPrice = targetPrices.find((_price) => _price.symbol == token.pair_token)?.usdPrice;
        return { token_id: token.token_id, symbol: token.symbol, usdPrice: _usd_token_price };
      })
    );

    console.log(targetPrices);
    console.log(tokenPrices)
    setTokenPriceList([...tokenPrices, ...targetPrices]);
    return false;
  };

  return useQuery('token-price-lists', fetchData, {
    refetchOnWindowFocus: false,
    enabled: true,
    staleTime: Infinity,
    retryDelay: 4000,
    // refetchInterval: TOKEN_POLLING_INTERVAL,
    retry: true,
  });
};

export const useWalletPairBalance = (_token, _pairToken, _chainId) => {
  const setTokenPairBalance = useSetRecoilState(atomTokenPairBalance);

  const tokenPairlist = useRecoilValue(atomTokenPairList);
  const targetTokenList = useRecoilValue(atomTargetTokenList);

  const { isConnected, address } = useAccount();

  const fetchData = async () => {
    if (!isConnected || !address || isEmpty(tokenPairlist) || isEmpty(targetTokenList)) {
      return false;
    }

    let usdBalance = 0;

    try {
      let chain = CHAIN_LIST.find((_chain) => _chain.chainId === _chainId);
      const mainToken = tokenPairlist.find(
        (token) =>
          token.symbol === _token?.toUpperCase() &&
          token.chain_id === _chainId &&
          token.pair_token === _pairToken?.toUpperCase()
      );
      const web3 = new Web3(chain?.rpcUrls[0]);
      const _tokenBalance = await getTokenBalance(address, mainToken.address, web3, mainToken.decimal);

      chain = CHAIN_LIST.find((_chain) => _chain.chainId === _chainId);
      const pairToken = targetTokenList.find(
        (token) => token.symbol === _pairToken?.toUpperCase() && token.chain_id === _chainId
      );
      const pairWeb3 = new Web3(chain?.rpcUrls[0]);
      const _pairBalance = await getTokenBalance(address, pairToken.address, pairWeb3, pairToken.decimal);

      let _tokenPrice = await getTokenPrice(_token);
      const _pairPrice = await getTokenPrice(_pairToken);

      _tokenPrice = !_tokenPrice ? mainToken.price * _pairPrice : _tokenPrice;
      usdBalance = +_tokenBalance * _tokenPrice + +_pairBalance * _pairPrice;

      setTokenPairBalance([_tokenBalance, _pairBalance, usdBalance]);
    } catch (e) {
      throw new Error('wallet-pair-balance', e);
    }

    return false;
  };

  return useQuery('wallet-pair-balances', fetchData, {
    refetchOnWindowFocus: false,
    enabled: true,
    staleTime: Infinity,
    retryDelay: 4000,
    // refetchInterval: WALLET_POLLING_INTERVAL,
    retry: true,
  });
};

export const useWalletCaladexBalance = (authToken, user_id) => {
  const setWalletCaladexBalance = useSetRecoilState(atomWalletCaladexBalance);

  const tokenPairlist = useRecoilValue(atomTokenPairList);
  const targetTokenList = useRecoilValue(atomTargetTokenList);

  const fetchData = async ({ queryKey }) => {
    if (isEmpty(tokenPairlist) || isEmpty(targetTokenList)) {
      return [];
    }

    const [_, _authToken, _user_id] = queryKey;

    if (!_authToken || !_user_id) {
      throw new Error('wallet-caladex-balance', 'User not logged in or data is unavailable.');
    }

    try {
      const response = await Axios.post(
        `${BACKEND_API_URL}/balance/get/${_user_id}`,
        {},
        { headers: { Authorization: `Bearer ${_authToken}` } }
      );
      const caladexBalances = response.data.data.doc;
      const utillityTokenList = [];
      tokenPairlist.map((value, index, array) => {
        if (utillityTokenList.find(token => token.token_id === value.token_id)) {
          return false
        }
        utillityTokenList.push(value);
        return true;
      })
      const balances = [...utillityTokenList, ...targetTokenList].map((_token, _index) => {
        const caladex_balance =
          caladexBalances.find((_balance) => _balance.token_id?._id === (_token.token_id ?? _token._id))
            ?.caladex_balance ?? 0;
        const order_balance =
          caladexBalances.find((_balance) => _balance.token_id?._id === (_token.token_id ?? _token._id))
            ?.order_balance ?? 0;

        return { token_id: { ..._token, _id: _token.token_id ?? _token._id }, caladex_balance, order_balance };
      });

      setWalletCaladexBalance(balances);
    } catch (e) {
      throw new Error('wallet-caladex-balance', e);
    }

    return false;
  };

  return useQuery(['wallet-caladex-balances', authToken, user_id], fetchData, {
    refetchOnWindowFocus: false,
    enabled: true,
    staleTime: Infinity,
    retryDelay: 4000,
    // refetchInterval: TOKEN_POLLING_INTERVAL,
    retry: true,
  });
};

export const useNewsList = () => {
  const fetchData = async () => {
    const response = await Axios.post(`${BACKEND_API_URL}/news/get`, {});

    const { data } = response;

    return data.data;
  };

  return useQuery('news-list', fetchData, {
    refetchOnWindowFocus: false,
    enabled: true,
    staleTime: Infinity,
    // retryDelay: 4000,
    // refetchInterval: TOKEN_POLLING_INTERVAL,
    retry: true,
  });
};

export const useCustomerStoryList = () => {
  const fetchData = async () => {
    const response = await Axios.post(`${BACKEND_API_URL}/customer-success/get`, {});

    const { data } = response;
    return data.data;
  };

  return useQuery('customer-success-list', fetchData, {
    refetchOnWindowFocus: false,
    enabled: true,
    staleTime: Infinity,
    // retryDelay: 4000,
    // refetchInterval: TOKEN_POLLING_INTERVAL,
    retry: true,
  });
};

export const useAccountInfo = (authToken, id) => {
  const fetchData = async () => {
    const response = await Axios.post(
      `${BACKEND_API_URL}/auth/my-account/${id}`,
      {},
      { headers: { Authorization: `Bearer ${authToken}` } }
    );

    const { data } = response;

    return data.data;
  };

  return useQuery('account-info', fetchData, {
    refetchOnWindowFocus: false,
    enabled: true,
    staleTime: Infinity,
    // retryDelay: 4000,
    // refetchInterval: TOKEN_POLLING_INTERVAL,
    retry: true,
  });
};

export const useMyInfo = (authToken) => {
  const fetchData = async () => {
    const response = await Axios.post(
      `${BACKEND_API_URL}/user/me`,
      {},
      { headers: { Authorization: `Bearer ${authToken}` } }
    );

    const { data } = response;

    return data.data;
  };

  return useQuery('my-account', fetchData, {
    refetchOnWindowFocus: false,
    enabled: true,
    staleTime: Infinity,
    // retryDelay: 4000,
    // refetchInterval: TOKEN_POLLING_INTERVAL,
    retry: true,
  });
};

const getSamePriceMergedOrderList = (_orders) => {
  if (!_orders?.length) {
    return _orders;
  }

  let resultArray = [];
  let price = _orders[0]?.price;
  let amount = 0;
  let orderId = _orders[0]?._id;

  for (let _order of _orders) {
    if (price === +_order.price) {
      amount += +_order.remain_amount;
    } else {
      resultArray.push({ orderId, price, remain_amount: amount });

      
      orderId = _order._id;
      price = +_order.price;
      amount = +_order.remain_amount;
    }
  }

  resultArray.push({ orderId, price, remain_amount: amount });

  return resultArray;
};
