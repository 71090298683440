import React, { useEffect, useState } from 'react';
import { Accordion, Button, Card, Nav } from 'react-bootstrap';
import PageTitle from '../element/page-title';
import { StakeDialog } from '../element/stake-dialogue';
import Footer2 from '../layout/footer2';
// import { Row, Col, Card } from 'react-bootstrap';
import Header2 from '../layout/header2';
import BannerVideo from '../../images/farming-video.mp4';
import { useFarmingPoolList } from '../../state/hooks';
import { useRecoilValue } from 'recoil';
import { atomFarmingPoolList, atomTargetTokenList, atomTokenPairList } from '../../state/atoms';
import { isEmpty, isUndefined } from 'lodash';
import Web3 from 'web3';
import { FARMINGABI, getTokenInfo } from '../../shared/web3';
import { BACKEND_FILE_URL, CHAIN_LIST, FARMING_ADDRESSES } from '../../shared/constants';
import { CircularProgress } from '@mui/material';
import { toast } from 'react-hot-toast';
import { StakedAmount } from '../element/staked-amount';
import { PendingAmount } from '../element/pending-amount';
import { getAPY, toFixed } from '../../shared/helpers';
import { LastStakeTime } from '../element/last-stake-time';
import BigNumber from 'bignumber.js';
import { useAccount, useContractWrite, useNetwork } from 'wagmi';
import { waitForTransaction } from '@wagmi/core';

function FarmPage() {
  const [modalShow, setModalShow] = useState(false);

  const { isConnected, address } = useAccount();

  const { chain } = useNetwork();

  const tokenPairList = useRecoilValue(atomTokenPairList);
  const targetTokenList = useRecoilValue(atomTargetTokenList)
  const poolList = useRecoilValue(atomFarmingPoolList);
  console.log(poolList, "======")
  const { refetch: poolRefetch } = useFarmingPoolList();

  const [poolFullInfoList, setPoolFullInfoList] = useState([]);
  const [selectedPoolData, setSelectedPoolData] = useState({});
  const [farmingAddress, setFarmingAddress] = useState(FARMING_ADDRESSES['0x1']);
  const [isHarvesting, setIsHarvesting] = useState(false);
  const [reloading, setReloading] = useState(false);

  useEffect(() => {
    if (isConnected) {
      setFarmingAddress(FARMING_ADDRESSES[Web3.utils.toHex(chain?.id)] ?? FARMING_ADDRESSES['0x1']);
    }
  }, [isConnected, chain]);

  const { writeAsync: harvestWrite } = useContractWrite({
    chainId: chain?.id,
    address: farmingAddress,
    abi: FARMINGABI,
    functionName: 'harvest',
    enabled: false,
  });

  useEffect(() => {
    if (!isConnected || !address) {
      toast.error('Please connect your wallet to stake!');
      //   walletConnect(activate);
    }
  }, []);

  useEffect(() => {
    const getPoolFullInfoList = async () => {
      if (isEmpty(tokenPairList) || isEmpty(poolList)) {
        return [];
      }

      const poolFullInfoList = await Promise.all(
        poolList.map(async (value) => {
          const chainPoolFullInfoList = await Promise.all(
            value.pools.map(async (_pool) => {
              const _tokenInfo = [...tokenPairList, ...targetTokenList].find(
                (_token) => _token.chain_id === value.chainId && _token.address?.toLowerCase() === _pool.poolInfo.lpToken?.toLowerCase()
              );

              if (isUndefined(_tokenInfo)) {
                /**
                 * TODO- getting token info logic if token is not listed in caladex platform!
                 */

                const web3 = new Web3(CHAIN_LIST?.find((_chain) => _chain.chainId == value.chainId)?.rpcUrls[0]);
                const _tokenInfo = await getTokenInfo(_pool.poolInfo.lpToken, web3);

                return { ..._pool, ..._tokenInfo };
              }

              return {
                ..._pool,
                name: _tokenInfo?.name,
                symbol: _tokenInfo?.symbol,
                logo_url: _tokenInfo?.logo_url,
                decimal: _tokenInfo?.decimal,
              };
            })
          );

          return { chainId: value.chainId, pools: chainPoolFullInfoList };
        })
      );

      return setPoolFullInfoList(poolFullInfoList);
    };

    if (!isEmpty(poolList) && !isEmpty(tokenPairList)) getPoolFullInfoList();
  }, [poolList, tokenPairList, reloading]);

  const handleStake = async (_poolData, _chainId) => {
    if (Web3.utils.toHex(chain?.id) !== _chainId) {
      const chainName = CHAIN_LIST.find((chain) => chain.chainId === _chainId).chainName;
      toast.error(`Please switch to ${chainName}`);
      return false;
    }
    setSelectedPoolData({ chainId: _chainId, ..._poolData });
    setModalShow(true);
  };

  const handleHarvest = async (pool, _chainId) => {
    const { index } = pool;
    if (Web3.utils.toHex(chain?.id) !== _chainId) {
      const chainName = CHAIN_LIST.find((chain) => chain.chainId === _chainId).chainName;
      toast.error(`Please switch to ${chainName}`);
      return false;
    }
    const _toast = toast.loading('Harvesting token...');
    setIsHarvesting(true);
    try {
      const { hash } = await harvestWrite({
        args: [index, 0],
        from: address,
      });
      const data = await waitForTransaction({
        confirmations: 1,
        hash,
      });
      console.log(data);
      toast.success('Token Successfully Harvested!');
    } catch (e) {
      console.log('Error-handleHarvest:', e);
      toast.error(e.message);
    } finally {
      setIsHarvesting(false);
      toast.dismiss(_toast);
      setReloading((prev) => !prev);
    }

    return false;
  };

  return (
    <>
      <Header2 activePage={`farm`} />
      <PageTitle />

      <div className="content-body">
        <div className="container">
          <div className="row">
            {/* <div className="col-xl-12 col-lg-12 col-md-12 text-center">
                            <h2 className='text-primary'>Coming Soon...</h2>
                        </div> */}
            <div className="col-xl-12 col-lg-12 col-md-12 text-center mb-5">
              <h2 className="my-2">Caladex Farming, dedicated to increasing user staking income</h2>
              <h4>Simple & Secure. Search popular coins and start earning.</h4>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12"></div>
            <div className="col-xl-12">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">All Farms</h4>
                </div>
                <div className="card-body">
                  <div className="col-md-12 my-3">
                    <Nav
                      variant="pills"
                      defaultActiveKey={`tab1`}
                    >
                      <Nav.Link eventKey="tab1">Popular Coins</Nav.Link>
                      <Nav.Link eventKey="tab2">Best for Beginners</Nav.Link>
                      <Nav.Link eventKey="tab3">New Listing</Nav.Link>
                    </Nav>
                  </div>
                  <div className="transaction-table">
                    <div className="table-responsive">
                      <table className="table table-striped mb-0 table-responsive-sm">
                        <thead>
                          <tr>
                            <th className="farm-logo-td text-primary">Coin</th>
                            <th>Network</th>
                            <th>EST.APY</th>
                            <th>Staked Amount</th>
                            <th>Staked Pool</th>
                            <th>EST.Reward</th>
                            <th>Unstake Fee</th>
                            <th>Harvest Fee</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {!isEmpty(poolFullInfoList) ? (
                            poolFullInfoList.map((_chainData, index) =>
                              _chainData.pools.map((pool, index) => (
                                <tr key={`farm-pool-list=${index}`}>
                                  <td className="farm-logo-td text-white-50">
                                    <i className="cc">
                                      <img
                                        src={`${BACKEND_FILE_URL}/${pool?.logo_url}`}
                                        style={{ width: '30px' }}
                                        iconClass="mdi-coin"
                                      />
                                    </i>{' '}
                                    {pool.symbol}
                                  </td>

                                  <td className="text-white-50">{CHAIN_LIST.find((chain) => chain.chainId === _chainData.chainId)?.chainName}</td>
                                  <td className="text-success">{getAPY(pool.poolInfo, _chainData.chainId)}</td>
                                  <td className="text-white-50">
                                    {address ? (
                                      <StakedAmount
                                        account={address}
                                        poolIndex={pool.index}
                                        chainId={_chainData.chainId}
                                        decimal={pool.decimal}
                                        reloading={reloading}
                                      />
                                    ) : (
                                      ' - '
                                    )}
                                  </td>
                                  <td className="text-white-50">
                                    {toFixed(
                                      new BigNumber(pool.poolInfo.totalStakedAmount).shiftedBy(-pool.decimal).toFixed(4)
                                    )}
                                  </td>
                                  <td className="text-white-50">
                                    {address ? (
                                      <PendingAmount
                                        account={address}
                                        poolIndex={pool.index}
                                        chainId={_chainData.chainId}
                                        decimal={pool.decimal}
                                      />
                                    ) : (
                                      ' - '
                                    )}
                                  </td>
                                  <td className="text-white-50">
                                    {address ? (
                                      <LastStakeTime
                                        account={address}
                                        poolIndex={pool.index}
                                        chainId={_chainData.chainId}
                                        decimal={pool.decimal}
                                        reloading={reloading}
                                      />
                                    ) : (
                                      ' - '
                                    )}
                                  </td>
                                  <td className="text-white-50">{pool.harvestFee} %</td>
                                  <td className="d-flex flex-md-row">
                                    <Button
                                      variant="primary"
                                      size="lg"
                                      onClick={() => handleStake(pool, _chainData.chainId)}
                                    >
                                      <i className="mdi mdi-wallet"></i>
                                    </Button>
                                    <Button
                                      variant="warning"
                                      size="lg"
                                      disabled={isHarvesting || !harvestWrite}
                                      onClick={() => handleHarvest(pool, _chainData.chainId)}
                                    >
                                      <i className="mdi mdi-tractor"></i>
                                    </Button>
                                  </td>
                                </tr>
                              ))
                            )
                          ) : (
                            <tr>
                              <td
                                className="text-success"
                                colSpan={9}
                              >
                                <div className="d-flex justify-content-center">
                                  <CircularProgress />
                                </div>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <StakeDialog
              show={modalShow}
              poolData={selectedPoolData}
              onHide={() => setModalShow(false)}
              setReloading={setReloading}
            />
            <div className="col-xl-6 col-lg-6 col-md-12">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">FAQ</h4>
                </div>
                <div className="card-body">
                  <Accordion
                    defaultActiveKey="0"
                    id="accordion-faq"
                    className="accordion"
                  >
                    <Card>
                      <Accordion.Toggle
                        as={Card.Header}
                        eventKey="0"
                      >
                        <h5>What is Caladex farming?</h5>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="0">
                        <Card.Body>
                          Caladex farming is an investment practice that involves locking crypto on the Caladex platform
                          for token rewards.
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    <Card>
                      <Accordion.Toggle
                        as={Card.Header}
                        eventKey="1"
                      >
                        <h5>How Long Will it Take To Get My my token reward?</h5>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="1">
                        <Card.Body>Tokens are immediately deposited on your wallet as soon you harvest.</Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12 mb-5">
              <video
                width="100%"
                height="auto"
                muted
                autoPlay
                loop
              >
                <source
                  src={BannerVideo}
                  type="video/mp4"
                />
              </video>
            </div>
          </div>
        </div>
      </div>

      <Footer2 />
    </>
  );
}

export default FarmPage;
