import React from 'react';
import { Link } from 'react-router-dom';
// import AboutUs from '../../files/About-Us.pdf'
// import CookiePolicy from '../../files/Cookie-Policy.pdf'
// import PrivacyPolicy from '../../files/Privacy-Policy.pdf'
// import TermsofUse from '../../files/Terms-of-Use.pdf'
// import ESignConsent from '../../files/E-Sign consent.pdf'
import { BACKEND_API_URL } from '../../shared/constants';


function Bottom() {

    return (
        <>
            <div className="bottom section-padding pb-0 text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                            <div className="bottom-logo">
                                <img className="pb-3" src={require("../../images/logo.png")} alt="" style={{ maxWidth: "200px" }} />

                                <p>Ethereum, Polygon and BSC integration <br /> Decentralized/Hybrid trading <br /> A+ ranking for transparency and volume</p>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3">
                            <div className="bottom-widget">
                                <h4 className="widget-title">Company</h4>
                                <ul>
                                    <li><Link to={"/aboutus"}>About us</Link></li>
                                    <li><Link to={"/token-info"}>Listed assets</Link></li>
                                    <li><Link to={"#"}>Affiliated companies</Link></li>
                                    <li><Link to={"/api-doc"}>API Documentation</Link></li>
                                    {/* <li><Link to={{
                                        pathname: `${BACKEND_API_URL}/files/papers/caladex_api_doc_2023_08_14.pdf`
                                    }} target="_blank">API Documentation</Link></li> */}
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3">
                            <div className="bottom-widget">
                                <h4 className="widget-title">Quick link</h4>
                                <ul>
                                    <li><Link to={{ pathname: 'https://www.facebook.com/caladexorg' }} target={`_blank`}>Facebook</Link></li>
                                    <li><Link to={{ pathname: 'https://twitter.com/caladexorg' }} target={`_blank`}>Twitter</Link></li>
                                    <li><Link to={{ pathname: 'https://www.linkedin.com/in/antony-kamermans/' }} target={`_blank`}>Linkedin</Link></li>
                                    <li><Link to={{ pathname: 'https://discord.com/channels/942107737637146695/942107737637146697' }} target={`_blank`}>Discord</Link></li>
                                    <li><Link to={{ pathname: 'https://www.facebook.com/groups/1828044877265988' }} target={`_blank`}>Community</Link></li>
                                    {/* <li><Link to={TermsofUse} target="_blank">Terms of use</Link></li>
                                    <li><Link to={PrivacyPolicy} target="_blank">Private policy</Link></li>
                                    <li><Link to={CookiePolicy} target="_blank">Cookie policy</Link></li>
                                    <li><Link to={ESignConsent} target="_blank">E-Sign consent</Link></li> */}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className='d-flex flex-row justify-content-start py-3'>
                        <Link to={"/terms-of-use"} className={`me-4`}>Terms of use</Link>
                        <Link to={"/privacy-policy"} className={`me-4`}>Privacy policy</Link>
                        <Link to={"/cookie-policy"} className={`me-4`}>Cookie policy</Link>
                        <Link to={"/esign-consent"} className={`me-4`}>E-Sign consent</Link>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Bottom;