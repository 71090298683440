import React, { useState } from "react";
import { Link } from "react-router-dom";
import Footer2 from "../layout/footer2";
import Footer1 from "../layout/footer1";
import Header2 from "../layout/header2";
import Sidebar from "../layout/sidebar";
import Bottom from "../element/bottom";

export const APIDoc = () => {
    const available_coin = `
    {
        "code": "success",
        "data": [
            {
            "chain_id": "0x1",
            "name": "Calahex(Ethereum)",
            "symbol": "CAX",
            "decimal": 0,
            "address": "0x377C59Bd50AAB876E41231DEe4339BF8e14C48a9",
            "website_url": "www.caladex.org",
            "logo_url": "https://caladex.io/api/files/images/calahex.png",
            "issuer_name": "Antony Kamermans",
            "email_address": "antonykamermans@calahex.com",
            "short_version_pdf_url": "https://caladex.io/api/files",
            "long_version_pdf_url": "https://caladex.io/api/files"
            }
        ],
        "error": []
    }
    `
    const info_of_coin = `
    {
        "code": "success",
        "data": {
            "chain_id": "0x1",
            "name": "Calahex(Ethereum)",
            "symbol": "CAX",
            "decimal": 0,
            "address": "0x377C59Bd50AAB876E41231DEe4339BF8e14C48a9",
            "website_url": "www.caladex.org",
            "logo_url": "https://caladex.io/api/files/images/calahex.png",
            "issuer_name": "Antony Kamermans",
            "email_address": "antonykamermans@calahex.com",
            "short_version_pdf_url": "https://caladex.io/api/files",
            "long_version_pdf_url": "https://caladex.io/api/files"
        },
        "error": []
    }
    `

    const tickers_for_coin = `
    {
        "code": "success",
        "data": [
            {
            "symbol": "CAX",
            "pair_token": "DAI",
            "price": 0.054825,
            "day_change": 8.189442525900347,
            "day_high": 0.059,
            "day_low": 0.059,
            "volume": 2,
            "market_sell_price": 0.059,
            "market_buy_price": 0.05065,
            "timestamp": 1691851239138
            }
        ],
        "error": []
    }
    `
    const list_all_exchanges = `
    {
        "code": "success",
        "data": [
            {
            "symbol": "CAX",
            "pair_token": "DAI",
            "price": 0.054825,
            "day_change": 8.189442525900347,
            "day_high": 0.059,
            "day_low": 0.059,
            "volume": 2,
            "market_sell_price": 0.059,
            "market_buy_price": 0.05065,
            "timestamp": 1691851396775
            }
        ]
    }
    `
    const market_info_for_ticker = `
    {
        "code": "success",
        "data": {
            "symbol": "CAX",
            "pair_token": "DAI",
            "price": 0.054825,
            "day_change": 8.189442525900347,
            "day_high": 0.059,
            "day_low": 0.059,
            "volume": 2,
            "market_sell_price": 0.059,
            "market_buy_price": 0.05065,
            "timestamp": 1691851559756
        },
        "error": []
    }
    `
    const market_chart_for_ticker = `
    {
        "code": "success",
        "data": [
            {
                "x": "2023-04-26T07:08:22.031Z",
                "y": [
                    0.03425,
                    0.0385,
                    0.0385
                ]
            }
        ],
        "error": ""
    } 
    `

    return (
        <>
            <Header2 />
            <div className="content-body" style={{ marginTop: '45px' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-header fw-bold">
                                    <h3>
                                        API Documentation
                                        <br/>
                                        <br/>
                                        <h6>August 12, 2023</h6>
                                    </h3>
                                </div>
                                <div className="card-body">
                                    <div className="legal-doc">
                                        <span>
                                            <h4>1. Introduction</h4>
                                            <p>
                                                Caladex platform offers some public api endpoints for market
                                                aggregators.
                                            </p>
                                            <h4>2. Overview</h4>
                                            <p>
                                                Trade endpoint URL: <a href="https://caladex.io/api/{resource}" target="_blank">https://caladex.io/api/{'{'}resource{'}'}</a>
                                            </p>
                                            <div className="table-responsive container">
                                                <table className="table table-striped mb-0 table-responsive-sm">
                                                    <thead>
                                                        <tr>
                                                            <th>API</th>
                                                            <th>Method</th>
                                                            <th>Resource</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td className="text-white-50">Get Available Coins</td>
                                                            <td className="text-white-50">GET</td>
                                                            <td className="text-white-50">/coins/list</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-white-50">Get Information of a Coin</td>
                                                            <td className="text-white-50">GET</td>
                                                            <td className="text-white-50">/coins/{'{'}chain{'}'}/{'{'}symbol{'}'}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-white-50">List Tickers for a Coin</td>
                                                            <td className="text-white-50">GET</td>
                                                            <td className="text-white-50">/coins/{'{'}chain{'}'}/{'{'}symbol{'}'}/tickers</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-white-50">List All Exchanges</td>
                                                            <td className="text-white-50">GET</td>
                                                            <td className="text-white-50">/exchange/list</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-white-50">Get Market Information for a Ticker</td>
                                                            <td className="text-white-50">GET</td>
                                                            <td className="text-white-50">/coins/{'{'}chain{'}'}/{'{'}tickers{'}'}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-white-50">Get Market Chart for a Ticker</td>
                                                            <td className="text-white-50">GET</td>
                                                            <td className="text-white-50">/coins/{'{'}chain{'}'}/{'{'}tickers{'}'}/market_chart</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <br/>
                                            <h4>3. Coin APIs</h4>
                                            <span style={{ borderBottom: '1px solid' }}>Get Available Coins</span>
                                            <p>
                                                GET <a href="https://caladex.io/api/coins/list" target="_blank">https://caladex.io/api/coins/list</a>
                                            </p>
                                            <p>
                                                RESPONSE
                                            </p>
                                            <pre>
                                                <code>
                                                    {available_coin}
                                                </code>
                                            </pre>
                                            <span style={{ borderBottom: '1px solid' }}>Get Information of a Coin</span>
                                            <p>
                                                GET <a href="https://caladex.io/api/coins/{chain}/{symbol}" target="_blank">https://caladex.io/api/coins/{'{'}chain{'}'}/{'{'}symbol{'}'}</a>
                                            </p>
                                            <p>
                                                RESPONSE
                                            </p>
                                            <pre>
                                                <code>
                                                    {info_of_coin}
                                                </code>
                                            </pre>
                                            <span style={{ borderBottom: '1px solid' }}>List Tickers for a Coin</span>
                                            <p>
                                                GET <a href="https://caladex.io/api/coins/{chain}/{symbol}/tickers" target="_blank">https://caladex.io/api/coins/{'{'}chain{'}'}/{'{'}symbol{'}'}/tickers</a>
                                            </p>
                                            <p>
                                                RESPONSE
                                            </p>
                                            <pre>
                                                <code>
                                                    {tickers_for_coin}
                                                </code>
                                            </pre>
                                            <h4>4. Exchange APIs</h4>
                                            <span style={{ borderBottom: '1px solid' }}>List All Exchanges</span>
                                            <p>
                                                GET <a href="https://caladex.io/api/exchange/list" target="_blank">https://caladex.io/api/exchange/list</a>
                                            </p>
                                            <p>
                                                RESPONSE
                                            </p>
                                            <pre>
                                                <code>
                                                    {list_all_exchanges}
                                                </code>
                                            </pre>
                                            <span style={{ borderBottom: '1px solid' }}>Get Market Information for a Ticker</span>
                                            <p>
                                                GET <a href=" https://caladex.io/api/exchange/{chain}/{ticker}" target="_blank"> https://caladex.io/api/exchange/{'{'}chain{'}'}/{'{'}ticker{'}'}</a>
                                            </p>
                                            <p>
                                                RESPONSE
                                            </p>
                                            <pre>
                                                <code>
                                                    {market_info_for_ticker}
                                                </code>
                                            </pre>
                                            <span style={{ borderBottom: '1px solid' }}>Get Market Chart for a Ticker</span>
                                            <p>
                                                GET <a href=" https://caladex.io/api/exchange/{chain}/{ticker}/market_chart" target="_blank"> https://caladex.io/api/exchange/{'{'}chain{'}'}/{'{'}ticker{'}'}/market_chart</a>
                                            </p>
                                            <p>
                                                RESPONSE
                                            </p>
                                            <pre>
                                                <code>
                                                    {market_chart_for_ticker}
                                                </code>
                                            </pre>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >

            <Bottom />
            <Footer1 />
        </>
    )
}