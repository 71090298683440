// hosted test server url: https://caladex-backend.herokuapp.com/api
export const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL;
export const BACKEND_FILE_URL = process.env.REACT_APP_BACKEND_FILE_URL;

export const ORDER_TYPE = {
  LIMIT: 'limit',
  MARKET: 'market',
};

export const EXCHANGE_TYPE = {
  BUY: 'buy',
  SELL: 'sell',
};

export const ORDER_FILTER = {
  BUY: 'buy',
  SELL: 'sell',
  ALL: 'all',
};

export const USER_STATUS = {
  PENDING: 'pending',
  SUBMITTED: 'submitted',
  REVIEWING: 'reviewing',
  DENIED: 'denied',
  APPROVED: 'approved',
  VERIFYING: 'verifying',
  VERIFIED: 'verified',
  BLOCKED: 'blocked',
};

// related to http
export const HTTP_SUCCESS_STATUS = 200;
export const HTTP_CREATED_STATUS = 201;
export const HTTP_NO_CONTENT_STATUS = 204;
export const HTTP_CLIENT_ERROR_STATUS = 400;
export const HTTP_UNAUTH_ERROR_STATUS = 401;
export const HTTP_FORBID_ERROR_STATUS = 403;
export const HTTP_NOT_FOUND_ERROR_STATUS = 404;
export const HTTP_SERVER_ERROR_STATUS = 500;

// related code
export const HTTP_SUCCESS_CODE = 'success';
export const HTTP_ERROR_CODE = 'error';

// related recaptcha
export const RECAPTCHA_KEY = process.env.REACT_APP_RECAPTCHA_KEY;
export const REACT_APP_WC_PROJECT_ID = process.env.REACT_APP_WC_PROJECT_ID;

//related with Moonpay
export const MOONPAY_PUB_KEY = process.env.REACT_APP_MOONPAY_PUBLISHABLE_KEY;
export const MOONPAY_SECRET_KEY = process.env.REACT_APP_MOONPAY_SECRET_KEY;
export const MOONPAY_ENV = process.env.REACT_APP_MOONPAY_ENV;

// related caladex address
export const CALADEX_ADDRESSES = {
  '0x1': process.env.REACT_APP_ETH_CALADEX_ADDR,
  '0x61': process.env.REACT_APP_BSCTEST_CALADEX_ADDR,
  '0x89': process.env.REACT_APP_POLYGON_CALADEX_ADDR,
  '0x38': process.env.REACT_APP_BSC_CALADEX_ADDR,
  '0x13881': process.env.REACT_APP_POLYGON_TEST_CALADEX_ADDR,
};

// related farming contract address
export const FARMING_ADDRESSES = {
  '0x1': process.env.REACT_APP_ETH_FARMING_ADDR,
  '0x61': process.env.REACT_APP_BSCTEST_FARMING_ADDR,
  '0x89': '0x27B1A579487564534667ABdDB5B027550f86E58C',
  // '0x89': process.env.REACT_APP_POLYGON_FARMING_ADDR,
  '0x38': '0xbeC00bdB9D39736C5459e7b4156a8864D8CaCb3e',
  // '0x38': process.env.REACT_APP_BSC_FARMING_ADDR,
  '0x13881': process.env.REACT_APP_POLYGON_TEST_FARMING_ADDR,
};

// each block validation time for chains

export const BLOCK_VERIFY_TIMES = {
  '0x1': 12,
  '0x61': 3,
  '0x89': 3,
  '0x38': 2,
  '0x13881': 2,
};

export const UNSTAKE_FEES = [
  {
    minDays: 7,
    unstakeFee: 10,
    class: 'text-danger',
  },
  {
    minDays: 15,
    unstakeFee: 5,
    class: 'text-warning',
  },
  {
    minDays: 30,
    unstakeFee: 3,
    class: 'text-primary',
  },
];

export const CALADEX_ETH_ADDR = process.env.REACT_APP_ETH_CALADEX_ADDR;

// export const REACT_APP_INFURA_KEY = 'e67a2556dede4ff2b521a375a1905f8b';
export const REACT_APP_INFURA_KEY = process.env.REACT_APP_INFURA_KEY;

export const DEPOSIT_SIGNATURE = '0x8752a472e571a816aea92eec8dae9baf628e840f4929fbcc2d155e6233ff68a7';

export const DEFAULT_TOKEN_SYMBOL = 'CAX';

export const MAX_IMAGE_SIZE = 1000000;

/**
 *  constants for polling data
 */

export const WALLET_POLLING_INTERVAL = 60000;
export const TOKEN_POLLING_INTERVAL = 4000;

export const WALLET_OPTIONS = {
  METAMASK: 'metamask',
  WALLETCONNECT: 'walletconnect',
  COINBASE: 'coinbase',
};

export const SWALLOW_DEGITS = 2;
export const TOKEN_DIGITS = 6;
export const USD_DIGITS = 4;
export const DEEP_DIGITS = 10;

export const EXCHANGE_LIMIT = 0.01;

export const CHAIN_LIST = [
  {
    blockExplorerUrls: ['https://etherscan.io'],
    chainId: '0x1',
    chainName: 'Ethereum',
    nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 },
    rpcUrls: [
      'https://mainnet.infura.io/v3/e67a2556dede4ff2b521a375a1905f8b',
      'https://api.mycryptoapi.com/eth',
      'https://cloudflare-eth.com',
    ],
    urlName: 'ethereum',
    blockScanUrl: 'https://etherscan.io',
  },
  {
    blockExplorerUrls: ['https://polygonscan.com'],
    chainId: '0x89',
    chainName: 'Polygon',
    nativeCurrency: { name: 'Matic', symbol: 'MATIC', decimals: 18 },
    rpcUrls: [
      'https://polygon-mainnet.infura.io/v3/e67a2556dede4ff2b521a375a1905f8b',
      'https://rpc-mainnet.matic.quiknode.pro',
      'wss://ws-mainnet.matic.network',
      'https://rpc-mainnet.matic.network',
    ],
    urlName: 'polygon',
    blockScanUrl: 'https://polygonscan.com',
  },
  {
    blockExplorerUrls: ['https://bscscan.com'],
    chainId: '0x38',
    chainName: 'Binance',
    nativeCurrency: {
      name: 'Binance Coin',
      symbol: 'BNB',
      decimals: 18,
    },
    rpcUrls: [
      'https://bsc-dataseed1.binance.org',
      'https://bsc-dataseed2.binance.org',
      'https://bsc-dataseed3.binance.org',
      'https://bsc-dataseed4.binance.org',
      'https://bsc-dataseed1.defibit.io',
      'https://bsc-dataseed2.defibit.io',
      'https://bsc-dataseed3.defibit.io',
      'https://bsc-dataseed4.defibit.io',
      'https://bsc-dataseed1.ninicoin.io',
      'https://bsc-dataseed2.ninicoin.io',
      'https://bsc-dataseed3.ninicoin.io',
      'https://bsc-dataseed4.ninicoin.io',
      'wss://bsc-ws-node.nariox.org',
    ],
    urlName: 'binance',
    blockScanUrl: 'https://bscscan.com',
  },
  ...(process.env.NODE_ENV === 'development'
    ? [
      {
        blockExplorerUrls: ['https://testnet.bscscan.com'],
        chainId: '0x61',
        chainName: 'Smart Chain - Testnet',
        nativeCurrency: {
          name: 'Test Binance Coin',
          symbol: 'BNB',
          decimals: 18,
        },
        rpcUrls: ['https://data-seed-prebsc-2-s2.binance.org:8545/'],
        urlName: 'binance-test',
        blockScanUrl: 'https://testnet.bscscan.com',
      },
      {
        blockExplorerUrls: ['https://polygonscan.com'],
        chainId: '0x13881',
        chainName: 'Mumbai Testnet',
        nativeCurrency: {
          name: 'Mumbai Test Matic',
          symbol: 'Matic',
          decimals: 18,
        },
        rpcUrls: ['https://rpc-mumbai.maticvigil.com/'],
        urlName: 'polygon-test',
        blockScanUrl: 'https://polygonscan.com',
      },
      {
        blockExplorerUrls: ['https://sepolia.etherscan.io'],
        chainId: '0xAA36A7',
        chainName: 'Sepolia test network',
        nativeCurrency: {
          name: 'Sepolia ETH',
          symbol: 'SepoliaETH',
          decimals: 18,
        },
        rpcUrls: ['https://sepolia.etherscan.io'],
      },
      {
        blockExplorerUrls: [],
        chainId: '0x539',
        chainName: 'Local Network',
        nativeCurrency: { name: 'Ethereum', symbol: 'GETH', decimals: 18 },
        rpcUrls: ['http://192.198.123.101:7545'],
      },
    ]
    : []),
];
