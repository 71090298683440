import React, { useContext, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext';
import { escapeHtmlObject, isPhoneNumber, setRefreshToken } from '../../shared/helpers';
import Axios from 'axios';
import { BACKEND_API_URL, HTTP_SUCCESS_STATUS } from '../../shared/constants';
import * as Yup from 'yup';
import { toast } from 'react-hot-toast';

function Otp1() {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setLoading] = useState(false);

  const history = useHistory();

  const { currentUser, setAuthProcessing, setAuthenticated } = useContext(AuthContext);

  const handleSend = async () => {
    setLoading(true);
    setError('');
    if (!isPhoneNumber(phoneNumber)) {
      setError('Invalid phone number format detected.');
      setLoading(false);
      return false;
    }

    // send request to send otp code

    try {
      if (!currentUser?.email) {
        setAuthProcessing(0);
        setAuthenticated(false);
        setRefreshToken('');
        history.push('signin');
      }

      const payload = { ...escapeHtmlObject({ id: currentUser.id, mobile: isPhoneNumber(phoneNumber) }) };

      const payloadSchema = Yup.object().shape({
        id: Yup.string().min(8).max(255).required(),
        mobile: Yup.string().min(3).max(255).required(),
      });

      let validationResult = '';

      await payloadSchema.validate(payload).catch((err) => {
        validationResult = err.message;
        return false;
      });

      if (validationResult) {
        console.log(validationResult);
        toast.error('Invalid parameter detected.');
        setLoading(false);
        return false;
      }

      let response = await Axios.post(`${BACKEND_API_URL}/auth/send-otp`, { ...payload });
      if (response?.status !== HTTP_SUCCESS_STATUS) {
        throw new Error('Sending otp code failed.');
      }
      setAuthProcessing(2);
      history.push('otp-2');
    } catch (e) {
      if (e?.response?.data?.message) {
        console.log('Send-Otp@api-error:', e?.response?.data?.message);
        setError(e?.response?.data?.message);
      } else {
        console.error('Send-Otp@logic-error:', e.message);
        setError(e.message);
      }
    }
    setLoading(false);
    return false;
  };

  return (
    <>
      <div className="authincation section-padding">
        <div className="container h-100">
          <div className="row justify-content-center h-100 align-items-center">
            <div className="col-xl-5 col-md-6">
              <div className="mini-logo text-center my-5">
                <Link to={'./'}>
                  <img
                    src={require('../../images/logo.png')}
                    alt=""
                    width="300px"
                  />
                </Link>
              </div>
              <div className="auth-form card">
                <div className="card-body">
                  <Link
                    className="page-back text-muted"
                    to={'./signin'}
                  >
                    <span>
                      <i className="fa fa-angle-left"></i>
                    </span>{' '}
                    Back
                  </Link>
                  <h3 className="text-center">OTP Verification</h3>
                  <p className="text-center mb-5">We will send one time code on this number</p>
                  <form>
                    <div className="mb-3">
                      <label>Your phone number</label>
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <span className="input-group-text ps-4 pe-4">
                            <i className="fa fa-phone"></i>
                          </span>
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          value={phoneNumber}
                          placeholder="+1 12365480"
                          onChange={(e) => {
                            setPhoneNumber(e.target.value);
                          }}
                        />
                      </div>
                      {error && <p className="text-danger my-3 text-center">{error}</p>}
                    </div>
                    <div className="text-center mt-4">
                      {!isLoading ? (
                        <Link
                          to={'#'}
                          className="btn btn-success btn-block"
                          onClick={() => {
                            handleSend();
                          }}
                        >
                          Send
                        </Link>
                      ) : (
                        <i className="fa fa-spinner text-primary fa-spin fa-2x fa-fw"></i>
                      )}
                    </div>
                  </form>
                  <div className="new-account mt-3 d-flex justify-content-between">
                    <p>
                      Don't get code?{' '}
                      <Link
                        className="text-primary"
                        to={'/otp-1'}
                      >
                        Resend
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Otp1;
