import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import Web3 from 'web3';
import { CHAIN_LIST, FARMING_ADDRESSES } from '../../shared/constants';
import { getPoolUserInfoList, getUserUnstakeFee } from '../../shared/web3';
import { CircularProgress } from '@mui/material';
import BigNumber from 'bignumber.js';
import moment from 'moment';

export const LastStakeTime = ({ account, poolIndex, chainId, decimal, reloading }) => {
  const [userInfo, setUserInfo] = useState({});
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUserData = async () => {
      if (!account) {
        return false;
      }

      const chain = CHAIN_LIST.find((_chain) => _chain.chainId === chainId);
      const web3 = new Web3(chain?.rpcUrls[0]);
      const farmingAddress =
        FARMING_ADDRESSES[chain.chainId] === undefined ? FARMING_ADDRESSES['0x1'] : FARMING_ADDRESSES[chain.chainId];

      try {
        const _userInfo = await getUserUnstakeFee(account, poolIndex, farmingAddress, web3);
        setUserInfo(_userInfo);
      } catch (e) {
        console.log('Error-fetchUserData', e);
      } finally {
        setLoading(false);
      }
      return false;
    };

    fetchUserData();
  }, [account, reloading]);

  return (
    <>
      {!isLoading ? (
        userInfo?.unstakeFee + '%' ?? ' - '
      ) : (
        <CircularProgress style={{ width: '20px', height: '20px' }} />
      )}
    </>
  );
};
